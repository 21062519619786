import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import Typography from '../../components/Core/Typography';
import { emailIsValid, passwordAreEqual, passwordIsValid } from '../../utils';
import './register.scss';
import { AuthService } from '../../api';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import { RegisterReq } from '../../models/User';
import Toggle from '../../components/Core/Toggle';

const initUser: RegisterReq = {
    emailAddress: '',
    password: '',
    firstName: '',
    lastName: '',
    title: '',
    industry: '',
    confirmPassword: '',
    isExisting: false,
};

const Register: React.FC = () => {
    const [user, setUser] = React.useState(initUser);
    const [showError, setShowError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const history = useHistory();
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    const handleChange = (name: string, value: string | boolean) => {
        setUser({ ...user, [name]: value });
    };

    const handleRegister = () => {
        setLoading(true);
        const isValid =
            emailIsValid(user.emailAddress) &&
            passwordIsValid(user.password) &&
            passwordAreEqual(user.password, user.confirmPassword) &&
            user.firstName.length > 0 &&
            user.lastName.length > 0 &&
            user.title.length > 0 &&
            user.industry.length > 0;

        if (isValid) {
            setLoading(true);
            setShowError(false);
            AuthService.register(user)
                .then(() => {
                    new Promise((resolve) => {
                        notificationDispatch({
                            type: NotificationActionType.OPEN,
                            payload: {
                                text: `User registered Successfully!`,
                                status: 'info',
                                autoClose: true,
                            },
                        });
                        setLoading(false);
                        resolve('OK');
                    }).then(() => {
                        setLoading(false);
                        history.push('/');
                    });
                })
                .catch((error) => {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text:
                                (error.response && error.response.data && error.response.data.message) ||
                                error.message ||
                                error.toString(),
                            status: 'error',
                            autoClose: true,
                        },
                    });
                    setLoading(false);
                });
        } else {
            setShowError(true);
            setLoading(false);
        }
    };

    return (
        <section>
            <div className="register-form">
                <Typography variant="heading" weight={300} color="white">
                    REGISTER
                </Typography>
                <Typography variant="caption" textAlign="right" color="white">
                    <span className="color-error">*</span> indicates required
                </Typography>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body" color="white">
                            First Name <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={user.firstName}
                            name="firstName"
                            error={showError && user.firstName.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body" color="white">
                            Last Name <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={user.lastName}
                            name="lastName"
                            error={showError && user.lastName.length === 0}
                            errorText="required"
                        />
                    </div>
                </div>
                <div>
                    <Typography variant="body" color="white">
                        Email Address <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={user.emailAddress}
                        name="emailAddress"
                        error={showError && !emailIsValid(user.emailAddress)}
                        errorText="required"
                    />
                </div>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body" color="white">
                            Title <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={user.title}
                            name="title"
                            error={showError && user.title.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body" color="white">
                            Industry <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={user.industry}
                            name="industry"
                            error={showError && user.industry.length === 0}
                            errorText="required"
                        />
                    </div>
                </div>
                <div className="form-grid-half" style={{ alignItems: 'flex-end' }}>
                    <div>
                        <Typography variant="body" color="white">
                            Password <span className="color-error">* </span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={user.password}
                            name="password"
                            type="password"
                            error={(showError || user.password.length > 0) && !passwordIsValid(user.password)}
                            errorText="Invalid Password"
                        />
                    </div>
                    <div>
                        <Typography variant="body" color="white">
                            Confirm Password <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={user.confirmPassword}
                            type="password"
                            name="confirmPassword"
                            error={
                                (showError || user.confirmPassword.length > 0) &&
                                !passwordAreEqual(user.password, user.confirmPassword)
                            }
                            errorText="Passwords do not match"
                        />
                    </div>
                </div>
                <Typography variant="caption" color="white">
                    Password must be 8 characters in length and must contain one lowercase, one uppercase letter, one
                    number and one special character (@, $, !, %, *, ?, &).
                </Typography>
                <br/>
                <Typography variant="body" color="white">
                    Are you an existing client of Olender Feldman?
                </Typography>
                <Toggle onChange={handleChange} name="isExisting" value={user.isExisting} />
                <div className="action-container">
                    <Typography variant="caption">
                        <Link to="/">
                            <span style={{ fontSize: '120%' }} className="text-action color-white">
                                Back to login
                            </span>
                        </Link>
                    </Typography>
                    <Button color="primary" loading={isLoading} onClick={handleRegister} textColor="white">
                        Register
                    </Button>
                </div>
            </div>
            <div className="intro-container">
                <div>
                    <Typography variant="body" weight={800} color="white">
                        Lorem, ipsum dolor sit
                    </Typography>
                    <Typography variant="subheading" color="white">
                        SIGN UP TODAY
                    </Typography>
                </div>
                <Typography variant="subtitle" weight={300} color="white">
                    amet consectetur adipisicing elit. Porro optio doloremque impedit exercitationem libero itaque
                    perferendis. Dolor molestias, eveniet aut quaerat maxime quibusdam sed natus? Consequatur
                    consectetur nisi nam laudantium!
                </Typography>
            </div>
        </section>
    );
};

export default Register;
