import Consensus from "../models/Consensus";
import instance from "./instance";

function getAllConsensus (): Promise<Consensus[]>{
    return instance.get("consensuses").then((response)=>{
        return response.data;
    })
}

function getConsensus(id: string) : Promise<Consensus>{
    return instance.get(`consensus/${id}`).then((response)=>{
        return response.data;
    })
}

function addConsensus(consensus: Consensus): Promise<boolean> {
    return instance.post("consensus", consensus).then((response)=>{
        return response.data;
    })
}

function updateConsensus(consensus: Consensus): Promise<boolean> {
    return instance.put("consensus", consensus).then((response)=>{
        return response.data;
    })
}

function deleteConsensus(id: string): Promise<boolean>{
    return instance.delete(`consensus/${id}`).then((response)=>{
        return response.data;
    })
}

export {addConsensus, updateConsensus, getConsensus, getAllConsensus, deleteConsensus};