import { SortDescriptor, orderBy, CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import React from "react";
import { useHistory } from "react-router-dom";
import { ConsensusService } from "../../api";
import Button from "../../components/Core/Button";
import { DataLoadingContext } from "../../components/Core/DataLoading";
import Consensus from "../../models/Consensus";
import ConsensusActions from "./ConsensusActions";
import { ExcelExport } from "@progress/kendo-react-excel-export";

interface PageState {
    skip: number;
    take: number;
}

const initialDataState: PageState = { skip: 0, take: 8};

const ConsensusMain: React.FC = () =>{
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const [consensus, setConsensus] = React.useState<Consensus[]>([]);
    const [filteredData, setFilteredData] = React.useState<Consensus[]>([]);
    const [sort, setSort] = React.useState<SortDescriptor[]>([]);
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();
    const history = useHistory();
    const _export = React.useRef<ExcelExport | null>(null);
    const exportExport = () => {
      if (_export.current !== null) {
        _export.current.save(consensus);
      }
    };
    React.useEffect(()=>{
        getData();
    }, [])

    React.useEffect(() => {
        if (filter) {
            setFilteredData(filterBy(consensus, filter));
        } else {
            setFilteredData(consensus);
        }
    }, [consensus]);

    const getData = () =>{
        toggleLoading(true);
        ConsensusService.getAllConsensus().then((response)=>{
            setConsensus(response);
        }).finally(()=> toggleLoading(false));
    };

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    const handleFilter = (event: GridFilterChangeEvent) => {
        setFilteredData(filterBy(consensus, event.filter));
        setFilter(event.filter);
    };

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <ConsensusActions consensus={props.dataItem as Consensus} fetchData={getData} />
            </td>
        );
    };



    return(
        <div className = "consensus">
            <div className="consensus-list">
            <ExcelExport ref={_export} fileName = "EmployeesExport.xlsx">
                <Grid
                    style = {{height: '100%'}}
                    data={orderBy(filteredData.slice(page.skip, page.take + page.skip), sort)}
                    sort = {sort}
                    sortable = {true}
                    onSortChange = {(e: GridSortChangeEvent) =>{
                        setSort(e.sort);
                    }}
                    skip = {page.skip}
                    take = {page.take}
                    total = {filteredData.length}
                    pageable = {true}
                    onPageChange = {pageChange}
                    filterable = {true}
                    filter = {filter}
                    onFilterChange = {handleFilter}
                     // eslint-disable-next-line react/prop-types
                    onRowClick = {(props) => history.push(`/employees/${props.dataItem.id}`)}
                >
                    <GridToolbar>
                    <Button onClick = {exportExport}>Export</Button>
                    <Button onClick = {() => history.push("/employees/add-employee")}>Add new employee</Button>
                    </GridToolbar>
                    <GridColumn field="firstName" title="First Name" />
                    <GridColumn field="lastName" title="Last Name" />
                    <GridColumn field="dob" title="Date of Birth" />
                    <GridColumn field="doe" title="Date of Employment" />
                    <GridColumn field="jobTitle" title="Job Title" />
                    <GridColumn field="department" title="Department" />
                    <GridColumn cell={GridActions} filterable={false} />

                </Grid>
                </ExcelExport>
            </div>
        </div>
    )
}

export default ConsensusMain;