import React from 'react';
import ContentManager from '.';
import { ContentService } from '../../api';
import EditablePage from '../../components/EditablePage';
import Content, { ContentStatus, ContentType } from '../../models/Content';
import { useParams } from 'react-router-dom';
import './article.scss';

const DefaultSingleTemplate: React.FC<{type:ContentType}> = ({type}) => {
    const [content, setContent] = React.useState<Content | null>(null);

    const { id } = useParams<{ id: string }>();

    React.useEffect(()=> {
        id !== 'add-new'
            ? ContentService.getContent(id).then((response) => {
                  setContent(response);
              })
            : setContent({
                type: type,
                status: ContentStatus.Draft,
                title: '',
                data: '',
            });
    }, [id])

    return (
        <EditablePage>
            <article>
                {content && (
                    <ContentManager content={content}>
                        <div className={`article ${ContentStatus[content.status]}`}>
                            <p className="content-title">{content.title}</p>
                            <div dangerouslySetInnerHTML={{ __html: content.data }}></div>
                        </div>
                    </ContentManager>
                )}
            </article>
        </EditablePage>
    );
};

export default DefaultSingleTemplate;
