import React from 'react';
import IconButton from '../../components/Core/IconButton';
import Typography from '../../components/Core/Typography';
import { urlIsValid } from '../../utils';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x.svg';
import Upload from '../../components/Upload';
import { ContentService } from '../../api';
import './featuredImage.scss';

type Props = {
    handleChange: (name: string, value: string) => void;
    source?: string;
};

const FeaturedImage: React.FC<Props> = ({ source, handleChange }) => {
    const [imageSrc, setImageSrc] = React.useState<string | null | undefined>(source);
    const [showUpload, setShowUpload] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (source && urlIsValid(source)) {
            setImageSrc(source);
        } else {
            setImageSrc('https://via.placeholder.com/728x320.png?text=Upload+featured+Image');
        }
    }, [source]);

    const handleError = () => {
        setImageSrc('https://via.placeholder.com/728x320.png?text=Upload+featured+Image');
    };

    const handleUpload = (file: File | File[]) => {
        setLoading(true);
        ContentService.uploadMedia(file as Blob, `author-image-${new Date().toLocaleTimeString()}`)
            .then((response) => {
                handleChange('featuredImage', response);
                setShowUpload(false);
            })
            .finally(() => setLoading(false));
    };
    return (
        <div className="featured-image-selector">
            <Typography variant="body">Featured Image</Typography>
            <div className="image-container">
                <img src={imageSrc as string} alt="featured-image" onError={handleError} />
                <div className="upload-button">
                    <IconButton
                        onClick={() => setShowUpload(true)}                        
                        Icon={EditIcon}
                        tooltip="Update picture"
                    />
                </div>
            </div>
            {showUpload && (
                <div className="image-upload">
                    <Upload
                        handleUpload={handleUpload}
                        isMultiple={false}
                        maxSize={500}
                        loading={loading}
                    />
                    <div className="close-button">
                        <IconButton onClick={() => setShowUpload(false)} Icon={CloseIcon} tooltip="Close" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeaturedImage;
