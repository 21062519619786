import React from "react";
type Props = {
    isCompleted: boolean,
    isCategory: boolean
}
const StepperMark:React.FC<Props>=({isCategory, isCompleted})=> {
        return <><div className={`stepper-mark ${isCategory ? 'big': 'small'} ${isCompleted? 'completed': ''}`}>
        </div></>
}



export default StepperMark;