import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { ConsensusService } from "../../api";
import { DataLoadingContext } from "../../components/Core/DataLoading";
import { NotificationActionType, NotificationContext } from "../../components/Core/Notification/state";
import Consensus from "../../models/Consensus";
import { AuthContext } from "../Authentication/state";
import ConsensusDetail from "./ConsensusDetail";
import ConsensusForm from "./ConsensusForm";

const initConsensus: Consensus = {
    userId: '',
    firstName: '',
    lastName: '',
    dob: '',
    doe: '',
    gender: '',
    ethnicity: '',
    jobTitle: '',
    classification: '',
    department: '',
    state: '',
    supervisor: '',
    directReports: [],
    // hourlyRate: undefined,
    // regularHours: undefined,
    // otHours: undefined,
    offerLetter: '',
    positionDescription: '',
    policySigned: '',
    handbooksRecieved: '',
    performanceIssues: ''
}


const ConsensusPage: React.FC = () =>{
    const { id } = useParams<{id: string}>();
    const [consensus, setConsensus] = React.useState<Consensus | null>(null);
    const[isFormView, setIsFormView] = React.useState(false);
    const{toggleLoading} = React.useContext(DataLoadingContext);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const { user } = React.useContext(AuthContext).state;
    const history  = useHistory();



    React.useEffect(() =>{
        if(id!=='add-employee'){
            getData();
        }
        else {
            if(user){
                setIsFormView(true);
                setConsensus({...initConsensus, userId: user?.id});
            }

        }
    },[]);

    const getData = () =>{
        toggleLoading(true);
        ConsensusService.getConsensus(id)
        .then((response)=>{
            setConsensus(response);
        })
        .finally(()=>{
            toggleLoading(false)
        }).catch((error) => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text:
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString(),
                    status: 'error',
                    autoClose: true,
                },
            });
            history.push('/employees');
           }) 
    };

    return consensus ? (
        <div className="consensus">
            {isFormView ? (
                <ConsensusForm consensus = {consensus} toggleView = {()=> setIsFormView(false)} />
            ) : (
                <ConsensusDetail consensus = {consensus} toggleView = {()=> setIsFormView(true)} />
            )}
        </div>
    ):
    <></>
}

export default ConsensusPage