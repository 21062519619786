import React from 'react';
import ErrorInfo from '../../components/Core/ErrorInfo';
import Content, { ContentConfig, ContentType } from '../../models/Content';
import { AuthContext } from '../Authentication/state';
import ContentActions from './ContentActions';
import ContentDetailForm from './ContentDetailForm';
import './contentmanager.scss';
import { ContentService } from '../../api';
import { useHistory } from 'react-router-dom';
import { urlIsValid } from '../../utils';
import Typography from '../../components/Core/Typography';
import ContentEditor from '../Editor';

type Props = {
    content: Content;
    children: React.ReactChild;
    config?: ContentConfig;
};

const ContentManager: React.FC<Props> = ({ content, children, config = {} }) => {
    const isEdit = React.useContext(AuthContext).state.isEditorView;
    const history = useHistory();
    const [error, setError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [_content, setContent] = React.useState<Content>(content);

    const handleChange = (name: string, value: string | boolean | number | null) => {
        setContent({ ..._content, [name]: value });
        setIsSuccess(false);
    };

    React.useEffect(()=> {
        setContent(content);
    }, [content])

    const handleSubmit = () => {
        if (isValid()) {
            setIsLoading(true);
            const request = content.id
                ? ContentService.updateContent(_content)
                : ContentService.createContent(_content);
            request
                .then(() => {
                    setIsLoading(false);
                    setIsSuccess(true);
                    setError('');
                    if (!content.id) {
                        history.goBack();
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error.message);
                });
        } else {
            let errorText = '';
            const typeLabel = ContentType[content.type].replace(/([A-Z]+)/g, ' $1').replace(/^ /, '');
            _content.title.length === 0 && (errorText += ` ${typeLabel} title is required. \n`);
            _content.data.length === 0 && (errorText += ` ${typeLabel} content is required. \n`);
            (config.hasAuthor && !(_content.authorId && _content.authorId.length > 0) && (errorText += ` ${typeLabel} author is required. \n`));
            (config.hasFeaturedImage && !(_content.featuredImage && urlIsValid(_content.featuredImage)) && (errorText += ` Valid featured image is required. \n`));
            (config.hasSummary && !(_content.summary && _content.summary.length > 40) && (errorText += ` ${typeLabel} summary should be at least 40 characters in length.`));
            setError(errorText);
        }
    };

    const isValid = () => {
        return (
            _content.title.length > 0 &&
            _content.data.length > 0 &&
            (config.hasAuthor ? _content.authorId && _content.authorId.length > 0 : true) &&
            (config.hasFeaturedImage ? _content.featuredImage && urlIsValid(_content.featuredImage) : true) &&
            (config.hasSummary ? _content.summary && (_content.summary as string).length > 40 : true)
        );
    };

    const handleDelete = () => {
        setIsLoading(true);
        ContentService.deleteContent(_content.id as string)
            .then(() => {
                setIsLoading(false);
                history.goBack();
                setError('');
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message);
            });
    };

    return (
        <>
            {isEdit && (
                <div className="paper bg-white">
                    <Typography variant="heading" color='primary' weight={600}>{content.id ? "Edit ": "Create " + ContentType[content.type].replace(/([A-Z]+)/g, ' $1').replace(/^ /, '')}</Typography>
                    <div className="content-manager">
                        <div style={{ width: '100%' }}>
                            {error.length > 0 && (
                                <>
                                    <ErrorInfo error={error} onClose={() => setError('')} />
                                    <br />
                                </>
                            )}
                            <ContentDetailForm handleChange={handleChange} content={_content} config={config} />
                        </div>
                        <ContentActions
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            content={_content}
                            handleDelete={handleDelete}
                            loading={isLoading}
                            isSuccess={isSuccess}
                            config={config}
                        />
                    </div>
                    <ContentEditor onChange={handleChange} value={content.data} name="data" />                    
                </div>
            )}
            {!isEdit && content.title.length >0 && <>{children}</>}
        </>
    );
};

export default ContentManager;
