import React from 'react';
import Logo from '../../assets/images/hires_logo.png';
import Typography from '../../components/Core/Typography';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import { Link, useParams } from 'react-router-dom';
import { passwordAreEqual, passwordIsValid } from '../../utils';
import { AuthService } from '../../api';
import { AuthContext } from '../Authentication/state';

type resetPassword = {
    password: string;
    confirmPassword: string;
};
const ResetPassword: React.FC = () => {
    return (
        <div className="page forgot-password-page">
            <section>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                <Typography variant="heading" weight={600} textAlign="center">
                    CORSIGHT
                </Typography>
                <br />
                <ResetPasswordForm />
            </section>
        </div>
    );
};

export const ResetPasswordForm: React.FC = () => {
    const [resetPassword, setResetPassword] = React.useState<resetPassword>({ password: '', confirmPassword: '' });
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const { token } = useParams<{ token: string}>();
    const { emailAddress } = useParams<{ emailAddress: string}>();
    const authState = React.useContext(AuthContext).state;

    const handleChange = (name: string, value: string) => {
        setResetPassword({ ...resetPassword, [name]: value });
    };

    const handleResetPassword = () => {
        setIsLoading(true);
        if (authState.isLoggedIn) {
            AuthService.resetUserPassword(resetPassword.password)
                .then(() => {
                    setIsSuccess(true);
                    setError('');
                    setIsLoading(false);
                })
                .catch((e) => {
                    setError(e.message);
                    setIsLoading(false);
                });
        } else {
            AuthService.resetPassword(token, emailAddress, resetPassword.password)
                .then(() => {
                    setIsSuccess(true);
                    setError('');
                    setIsLoading(false);
                })
                .catch((e) => {
                    setError(e.message);
                    setIsLoading(false);
                });
        }
    };
    return (
        <>
            {!isSuccess ? (
                <>
                    <Typography variant="subheading" color="secondary">
                        CREATE A NEW PASSWORD
                    </Typography>
                    {error.length > 0 && (
                        <Typography variant="body" color="error">
                            {error}
                        </Typography>
                    )}<br/>
                        <Typography variant="caption">
                            Password must be 8 characters in length and must contain one lowercase, one uppercase
                            letter, one number and one special character (@, $, !, %, *, ?, &).
                        </Typography>
                    <InputField
                        onChange={handleChange}
                        name="password"
                        type="password"
                        variant="outline"
                        outlineLabel="Password"
                        required={true}
                        value={resetPassword.password}
                        error={resetPassword.password.length > 0 && !passwordIsValid(resetPassword.password)}
                    />

                    <InputField
                        onChange={handleChange}
                        name="confirmPassword"
                        type="password"
                        variant="outline"
                        outlineLabel="Confirm Password"
                        required={true}
                        value={resetPassword.confirmPassword}
                        error={
                            resetPassword.confirmPassword.length > 0 &&
                            !passwordAreEqual(resetPassword.password, resetPassword.confirmPassword)
                        }
                        errorText="passwords do not match"
                    />
                    <div className="action-container">
                        <Button
                            color="primary"
                            className="mx-auto d-block"
                            onClick={handleResetPassword}
                            disabled={
                                !passwordIsValid(resetPassword.password) ||
                                !passwordAreEqual(resetPassword.password, resetPassword.confirmPassword)
                            }
                            loading={isLoading}
                        >
                            Reset Password
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <Typography variant="title" color="secondary" textAlign="center">
                        Your password was successfully reset!
                    </Typography>
                    {!authState.isLoggedIn && (
                        <Link to="/">
                            <Button color="primary" className="mx-auto d-block">
                                Back to login
                            </Button>
                        </Link>
                    )}
                </>
            )}
        </>
    );
};

export default ResetPassword;
