import React from 'react';
import { UserSurveyGrid } from '../../models/Survey';
import { LoginUser } from '../../models/User';
import { ReactComponent as LinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import IconButton from '../../components/Core/IconButton';
import { Link } from 'react-router-dom';
import { SurveyService } from '../../api';

const SurveyActions: React.FC <{user?: LoginUser, survey: UserSurveyGrid}> = ({user, survey}) =>{

    const isComplete = survey.totalSteps === survey.currentStep;
    const isAuthorized = user?.id === survey.userId || isComplete;

    const handleClick = () =>{
                   SurveyService.exportReport(survey.surveyId as string).then((response)=>{
                     const pdfWindow = window.open("");
                      pdfWindow?.document.write(
                 "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                  encodeURI(response) + "'></iframe>"
                       )   
                  })            
    }
    return (
        <>
        {isAuthorized && 
            <>
            {isComplete ? 
                
                <IconButton Icon={LinkIcon} color="info" tooltip='View Survey' onClick={handleClick}/> 
                :
                <Link to={`surveys/${survey.surveyId}`}>
                     <IconButton Icon={EditIcon} color="edit" tooltip='Edit Survey'/>
                </Link>
                
                
            }
            </>       
        }
        </>
    )
}

export default SurveyActions;