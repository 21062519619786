import SurveyTemplate, { ReportTemplatePage, SurveyCategory,UserSurveyGrid, UserSurveyViewModel } from '../models/Survey';
import instance from './instance';

// function getSurveys(): Promise<UserSurveyGrid[]> {
//     return instance.get(`/Survey/all`).then((response) => {
//         return response.data as UserSurveyGrid [];
//     });
// }

function getSurveys(pageNumber?: number): Promise<{ items: UserSurveyGrid[]; total: number }> {
    const reqUrl = pageNumber ? `/Surveys?page=${pageNumber}` : `/Surveys`;
    return instance.get(reqUrl).then((response) => {
        return response.data;
    });
}

function getSurveyViewModel(id?: string): Promise<UserSurveyViewModel> {
    const request = id ? `/Survey/${id}` : `/Survey/new`;
    return instance.get(request).then((response) => {
        return response.data;
    });
}

function exportReport(id: string): Promise<string> {
    return instance.get(`/Survey/${id}/report`).then((response) => {
        return response.data;
    });
}

function createSurveyTemplate(template: SurveyTemplate): Promise<boolean> {
    return instance.post(`/Survey/template`, template).then((response) => {
        return response.data;
    });
}

function updateSurveyTemplate(template: SurveyTemplate): Promise<boolean> {
    return instance.put(`/Survey/template`, template).then((response) => {
        return response.data;
    });
}

function activateTemplate(id: string): Promise<boolean> {
    return instance.put(`/Survey/template/activate/${id}`).then((response) => {
        return response.data;
    });
}

function getSurveyTemplate(id: string): Promise<SurveyTemplate> {
    return instance.get(`/Survey/template/${id}`).then((response) => {
        return response.data;
    });
}

function getAllTemplates(): Promise<SurveyTemplate[]> {
    return instance.get('/Survey/templates').then((response) => {
        return response.data;
    });
}

function getTemplateById(id: string): Promise<SurveyTemplate> {
    return instance.get(`/Survey/templates/${id}`).then((response) => {
        return response.data;
    });
}

function updateSurveyViewModel(survey: UserSurveyViewModel, isNew?: boolean): Promise<boolean> {
    const reqUrl = isNew ? `/Survey?isNew=${isNew}` : `/Survey`;
    return instance.put(reqUrl, survey).then((response) => {
        return response.data;
    });
}

function getReportPages(): Promise<ReportTemplatePage> {
    return instance.get(`/content/report-template-metadata`).then((response) => {
        return response.data;
    });
}

function uploadReportPages(req: { coverTemplate?: File; endTemplate?: File }): Promise<boolean> {
    const formData = new FormData();
    if (req.coverTemplate) {
        formData.append('coverTemplate', req.coverTemplate);
    }
    if (req.endTemplate) {
        formData.append('endTemplate', req.endTemplate);
    }
    return instance.post(`/content/report-template`, formData).then((response) => {
        return response.data;
    });
}

function revertTemplatePage(type: 0 | 1): Promise<boolean> {
    return instance.put(`/content/report-template/${type}/revert`).then((response) => {
        return response.data;
    });
}

function getAllCategories(): Promise<SurveyCategory[]> {
    return instance.get('/Survey/categories').then((response) => {
        return response.data;
    });
}
function addCategory(val: string): Promise<SurveyCategory[]> {
    return instance.post('/Survey/category', {category: val}).then((response) => {
        return response.data;
    });
}
export {
    getSurveys,
    getSurveyViewModel,
    createSurveyTemplate,
    updateSurveyViewModel,
    updateSurveyTemplate,
    activateTemplate,
    getSurveyTemplate,
    getAllTemplates,
    exportReport,
    getTemplateById,
    getReportPages,
    uploadReportPages,
    revertTemplatePage,
    getAllCategories,
    addCategory
};
