import React from 'react';
import { authReducer, initialState, AuthContext, AuthActionType } from '../Authentication/state';
import { AuthService } from '../../api';
import PageLoader from '../../components/PageLoader';
import { useLocation, useHistory } from 'react-router-dom';
import LogoutIdleTimer from '../../components/LogoutIdleTimer';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';

type Props = {
    children: React.ReactNode;
};

const Authentication: React.FC<Props> = ({ children }) => {
    const [state, dispatch] = React.useReducer(authReducer, initialState);
    const [authenticating, setAuthenticating] = React.useState(true);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
        handleAuthentication();
    }, []);

    const handleAuthentication = () => {
        setAuthenticating(true);
        const token = localStorage.getItem('hiring-token');
        if (token) {
            AuthService.loginWithToken()
                .then((response) => {
                    new Promise((resolve) => {
                        localStorage.setItem('hiring-token', response.accessToken);
                        dispatch({ type: AuthActionType.LOGIN, payload: response });
                        notificationDispatch({
                            type: NotificationActionType.OPEN,
                            payload: {
                                text: `Welcome back ${response.name}!`,
                                status: 'success',
                                autoClose: true,
                            },
                        });
                        if (location.pathname === '/') {
                            history.push('/surveys');
                        }
                        resolve('OK');
                    }).then(() => {
                        setAuthenticating(false);
                    });
                })
                .catch((e) => {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: e.message,
                            status: 'error',
                            autoClose: true,
                        },
                    });
                    setAuthenticating(false);
                });
        } else {
            setAuthenticating(false);
        }
    };

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {authenticating ? (
                <PageLoader />
            ) : (
                <>
                    <LogoutIdleTimer
                        isLoggedIn={state.isLoggedIn}
                        interval={30}
                        logout={() => dispatch({ type: AuthActionType.LOGOUT })}
                    />
                    {children}
                </>
            )}
        </AuthContext.Provider>
    );
};

export default Authentication;
