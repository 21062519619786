import React from 'react';
import Checkbox from '../../components/Core/Checkbox';
import InputRange from '../../components/Core/InputRange';
import Radio from '../../components/Core/Radio';
import Typography from '../../components/Core/Typography';
import { Reviewer, SurveyApplicability, SurveyQuestionErrors, UserResponseQuestion } from '../../models/Survey';
import DescriptionCard from './DescriptionCard';
type Props = {
    question: UserResponseQuestion;
    identifier: string;
    handleChange: (updatedQuestion: UserResponseQuestion, identifier: string) => void;
    errorIdentifiers: Array<SurveyQuestionErrors>;
};
const QuestionCard: React.FC<Props> = ({ question, identifier, handleChange, errorIdentifiers }) => {
    const [error, setError] = React.useState<SurveyQuestionErrors | null>(null);

    React.useEffect(() => {
        if (errorIdentifiers.findIndex((a) => a.identifier === identifier) === -1) {
            setError(null);
        } else {
            setError(errorIdentifiers.find((a) => a.identifier === identifier) as SurveyQuestionErrors);
        }
    }, [errorIdentifiers]);

    const onChange = (name: string, value: string | number | boolean | SurveyApplicability) => {
        handleChange({ ...question, [name]: value }, identifier);
    };

    const handleReviewerChange = (index: number) => {
        const reviewers = [...(question.reviewers as Reviewer[])];
        reviewers[index].isSelected = !reviewers[index].isSelected;
        const noneSelected = reviewers[4].isSelected;
        if (index !== 4 && noneSelected) {
            reviewers[4].isSelected = false;
        }
        if (index == 4 && noneSelected) {
            reviewers[0].isSelected =
                reviewers[1].isSelected =
                reviewers[2].isSelected =
                reviewers[3].isSelected =
                    false;
        }
        handleChange({ ...question, reviewers: reviewers }, identifier);
    };

    return (
        <>
            <div className="question-card">
                <div>
                    <div>
                    <Typography variant="subtitle" weight={600} color="secondary">
                        {identifier}. {question.title}
                    </Typography>
                    <DescriptionCard question={question} />
                    </div>
                    {error?.isApplicabilityError && (
                        <>
                            <Typography variant="body" color="error" textAlign="left">
                                Applicability of question is required
                            </Typography>
                        </>
                    )}
                    {error?.isYearsErrors && (
                        <>
                            <Typography variant="body" color="error" textAlign="left">
                                Please select the year since the last review
                            </Typography>
                        </>
                    )}
                    {error?.isReviewerError && (
                        <>
                            <Typography variant="body" color="error" textAlign="left">
                                Reviewed By Party is required
                            </Typography>
                        </>
                    )}

                    {!question.hasSubQuestions && (
                        <>
                            <div className="reviewed-by">
                                <Radio
                                    onChange={() => onChange('doesExists', SurveyApplicability.Yes)}
                                    label="Yes"
                                    checked={question.doesExists === SurveyApplicability.Yes}
                                />
                                <Radio
                                    onChange={() => onChange('doesExists', SurveyApplicability.No)}
                                    label="No"
                                    checked={question.doesExists === SurveyApplicability.No}
                                />
                                <Radio
                                    onChange={() => onChange('doesExists', SurveyApplicability.NA)}
                                    label="N/A"
                                    checked={question.doesExists === SurveyApplicability.NA}
                                />
                                <Radio
                                    onChange={() => onChange('doesExists', SurveyApplicability.Unknown)}
                                    label="Unknown"
                                    checked={question.doesExists === SurveyApplicability.Unknown}
                                />
                            </div>
                            <br />
                            {question.doesExists === SurveyApplicability.Yes && question.needsCouncelorReview && (
                                <>
                                    <div>
                                        <p>Year since last review:</p>
                                        <br />
                                        <InputRange
                                            onChange={onChange}
                                            name="yearSinceLastReview"
                                            value={
                                                question.yearSinceLastReview
                                                    ? parseInt(question.yearSinceLastReview)
                                                    : 0
                                            }
                                        />
                                    </div>
                                    <div>
                                        <p>Reviewed by:</p>
                                        <div className="reviewed-by">
                                            {question.reviewers.map((reviewer, index) => (
                                                <div key={index}>
                                                    <Checkbox
                                                        checked={reviewer.isSelected as boolean}
                                                        onChange={() => handleReviewerChange(index)}
                                                        label={reviewer.label}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            {/* <div className="hidden-desktop">
                <DescriptionCard question={question} />
            </div> */}
            {question.hasSubQuestions &&
                question.metaData.subQuestions &&
                question.metaData.subQuestions.map((subquestion, index) => (
                    <div key={index} className="survey-question">
                        <QuestionCard
                            question={subquestion as UserResponseQuestion}
                            identifier={`${identifier}.${index + 1}`}
                            handleChange={handleChange}
                            errorIdentifiers={errorIdentifiers}
                        />
                    </div>
                ))}
        </>
    );
};

export default QuestionCard;
