import React from 'react';
import { UserService } from '../../api';
import AccountWrapper from '../../components/AccountWrapper';
import { DataLoadingContext } from '../../components/Core/DataLoading';
import { SystemUser } from '../../models/User';
import { UserDetailCard } from '../Users/UserDetail';
import './profile.scss';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import IconButton from '../../components/Core/IconButton';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import Button from '../../components/Core/Button';
import { emailIsValid } from '../../utils';
import Typography from '../../components/Core/Typography';
import InputField from '../../components/Core/InputField';
import Toggle from '../../components/Core/Toggle';

const Profile: React.FC = () => {
    const [profile, setProfile] = React.useState<SystemUser | null>(null);
    const [updatedProfile, setUpdatedProfile] = React.useState<SystemUser | null>(null);
    const [isEdit, setIsEdit] = React.useState(false);
    const toggleLoading = React.useContext(DataLoadingContext).toggleLoading;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        toggleLoading(true);
        getData();
    }, []);

    const getData = () => {
        UserService.getProfile()
            .then((response) => {
                setProfile(response);
                setUpdatedProfile(response);
            })
            .finally(() => toggleLoading(false));
    };

    const handleUpdate = () => {
        const isValid = checkValidity(updatedProfile as SystemUser);
        if (isValid) {
            setShowError(true);
            toggleLoading(true);
            UserService.updateUser(updatedProfile as SystemUser)
                .then(() => {
                    setProfile(updatedProfile);
                    setIsEdit(false);
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: 'Profile information was successfully updated',
                            status: 'success',
                            autoClose: true
                        },
                    });
                })
                .catch((e) =>
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: e.message,
                            status: 'error',
                            autoClose: true
                        },
                    }),
                )
                .finally(() => toggleLoading(false));
        } else {
            setShowError(true);
        }
    };

    const handleChange = (name: string, value: string | boolean) => {
        setUpdatedProfile({ ...(updatedProfile as SystemUser), [name]: value });
    };

    const checkValidity = (user: SystemUser) => {
        if (
            emailIsValid(user.emailAddress) &&
            user.firstName.length > 0 &&
            user.lastName.length > 0 &&
            user.title.length > 0 &&
            user.industry.length > 0
        ) {
            return true;
        }
        return false;
    };

    return (
        <AccountWrapper>
            {profile && updatedProfile && (
                <>
                    {!isEdit ? (
                        <div className="paper user-detail profile">
                            <div className="edit-action">
                                <IconButton Icon={EditIcon} onClick={() => setIsEdit(true)} color="info" />
                            </div>
                            <UserDetailCard user={profile} />
                        </div>
                    ) : (
                        <div className="paper profile">
                            <Typography variant='heading' color="secondary">EDIT PROFILE</Typography>
                            <Typography variant="caption" textAlign="right" color="primary">
                                <span className="color-error">*</span> indicates required
                            </Typography>
                            <br/>
                            <div className="form-grid-half">
                                <div>
                                    <Typography variant="body" color="primary">
                                        First Name <span className="color-error">*</span>
                                    </Typography>
                                    <InputField
                                        onChange={handleChange}
                                        value={updatedProfile.firstName}
                                        name="firstName"
                                        error={showError && updatedProfile.firstName.length === 0}
                                        errorText="required"
                                    />
                                </div>
                                <div>
                                    <Typography variant="body" color="primary">
                                        Last Name <span className="color-error">*</span>
                                    </Typography>
                                    <InputField
                                        onChange={handleChange}
                                        value={updatedProfile.lastName}
                                        name="lastName"
                                        error={showError && updatedProfile.lastName.length === 0}
                                        errorText="required"
                                    />
                                </div>
                            </div>
                            <div>
                                <Typography variant="body" color="primary">
                                    Email Address <span className="color-error">*</span>
                                </Typography>
                                <InputField
                                    onChange={handleChange}
                                    value={updatedProfile.emailAddress}
                                    name="emailAddress"
                                    error={showError && !emailIsValid(updatedProfile.emailAddress)}
                                    errorText="required"
                                />
                            </div>
                            <div className="form-grid-half">
                                <div>
                                    <Typography variant="body" color="primary">
                                        Title <span className="color-error">*</span>
                                    </Typography>
                                    <InputField
                                        onChange={handleChange}
                                        value={updatedProfile.title}
                                        name="title"
                                        error={showError && updatedProfile.title.length === 0}
                                        errorText="required"
                                    />
                                </div>
                                <div>
                                    <Typography variant="body" color="primary">
                                        Industry <span className="color-error">*</span>
                                    </Typography>
                                    <InputField
                                        onChange={handleChange}
                                        value={updatedProfile.industry}
                                        name="industry"
                                        error={showError && updatedProfile.industry.length === 0}
                                        errorText="required"
                                    />
                                </div>
                            </div>
                            <Typography variant="body" color="primary">
                                Are you an existing client of Olender Feldman?
                            </Typography>
                            <Toggle onChange={handleChange} name="isExisting" value={updatedProfile.isExisting} />
                            <div className="action-container">
                                <Button
                                    onClick={() => {
                                        setIsEdit(false);
                                        setUpdatedProfile(profile);
                                    }}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button onClick={handleUpdate} color="primary">
                                    Update
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </AccountWrapper>
    );
};

export default Profile;
