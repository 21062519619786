import axios, { AxiosRequestHeaders } from 'axios';
import { ApiResponse } from '../models/ApiResponse';


const instance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
});
instance.interceptors.request.use(
    async function (config) {
        const headers: AxiosRequestHeaders = config.headers as AxiosRequestHeaders;
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('hiring-token');
        return config;
        return config;
    },
    function (err) {
        return Promise.reject(err);
    },
);

instance.interceptors.response.use((resp):Promise<ApiResponse<any>> => {
    const response:ApiResponse<any> = resp?.data;
    if(!response?.isSuccess) {
        return Promise.reject(response);
    }
    else{
        return Promise.resolve(response);
    }
}, () => {
    const networkIssue: ApiResponse<boolean> = {
        isSuccess: false,
        message: "Something went wrong. Please try again later.",
        statusCode: 500,
        data: false
    } 
    return Promise.reject(networkIssue);
})

export default instance;
