import React from 'react';
import AccountWrapper from '../../components/AccountWrapper';
import { ResetPasswordForm } from '../ResetPassword';

const UpdatePassword: React.FC = () => {
    return (
        <AccountWrapper><div className="paper">
            <ResetPasswordForm />
        </div>
        </AccountWrapper>
    );
};

export default UpdatePassword;
