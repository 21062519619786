import React from 'react';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as DisableIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as EnableIcon } from '../../assets/icons/unlock.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { ReactComponent as LinkIcon } from '../../assets/icons/external-link.svg';
import { UserService } from '../../api';
import { GridUser } from '../../models/User';
import { Link } from 'react-router-dom';
type Props = {
    user: GridUser;
    fetchData: () => void;
};
const UserActions: React.FC<Props> = ({ user, fetchData }) => {
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const enableUserDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Enable User',
                description: `Are you sure you want to enable user ${user.name}?`,
                handleConfirm: () => handleEnable(user.id as string),
            },
        });
    };

    const openDeleteUserDialog = (isDisabling: boolean) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: isDisabling ? 'Disable User' : 'Delete User',
                description: `Are you sure you want to ${isDisabling ? 'disable user' : 'delete user'} ${user.name}?`,
                handleConfirm: () => (isDisabling ? handleDisbale(user.id as string) : handleDelete(user.id as string)),
            },
        });
    };

    const handleDelete = (id: string) => {
        UserService.deleteUser(id).then(() => {
            fetchData();
        });
    };

    const handleEnable = (id: string) => {
        UserService.enableUser(id).then(() => {
            fetchData();
        });
    };

    const handleDisbale = (id: string) => {
        UserService.disableUser(id).then(() => {
            fetchData();
        });
    };
    return (
        <>
            <Link to={`users/${user.id}`}>
                <IconButton Icon={LinkIcon} color="info" tooltip='User Details' />
            </Link>
            {user.isDisabled && <IconButton Icon={DisableIcon} onClick={enableUserDialog} color="success" tooltip='Enable User' />}
            {!user.isDisabled && (
                <IconButton Icon={EnableIcon} onClick={() => openDeleteUserDialog(true)} color="error"  tooltip ='Disable User'/>
            )}
            <IconButton Icon={DeleteIcon} onClick={() => openDeleteUserDialog(false)} color="error" tooltip = 'Delete User' />
        </>
    );
};

export default UserActions;
