import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Core/Button';
import Typography from '../../components/Core/Typography';

const SurveyCallouts: React.FC<{ isAuthorized: boolean }> = ({ isAuthorized }) => {
    return (
        <div className="callout">
            <div className="paper">
                <Typography variant="title" color="secondary" textAlign="center">
                    TAKE A SURVEY
                </Typography>
                <Typography variant="body" textAlign="center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua.
                </Typography>
                <Link to={`/surveys/add-new`}>
                    <Button color="primary" className="d-block mx-auto">
                        Evaluate
                    </Button>
                </Link>
            </div>
            {isAuthorized && (
                <div className="paper">
                    <Typography variant="title" color="secondary" textAlign="center">
                        MANAGE SURVEY TEMPLATES
                    </Typography>
                    <Typography variant="body" textAlign="center">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.
                    </Typography>
                    <div className="callout-actions">
                        <Link to={`/surveys/admin/templates`}>
                            <Button color="primary" className="d-block mx-auto">
                                Manage Templates
                            </Button>
                        </Link>                        
                    </div>
                </div>
            )}
        </div>
    );
};

export default SurveyCallouts;
