import React from 'react';
import './inputrange.scss';

type Props = {
    name: string;
    value?: number;
    onChange: (name: string, value: string) => void;
    disabled?: boolean;
    required?: boolean;
};

const InputRange: React.FC<Props> = ({
    name,
    value = 0,
    onChange,
    disabled,
    required,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(name, e.target.value);
    };

    const getBackgroundStyle = () => {
        const width = ((value - 0) / (3 - 0)) * 100;
        return {
            background: `linear-gradient(to right, #016085 0%, #016085 ${width}%, #b7b8b9 ${width}%, #E0E3D4 100%)`,
        };
    };

    return (
        <div className="input-range-container">
            <div className="slider-container">
                <input
                    type="range"
                    onChange={handleChange}
                    disabled={disabled}
                    name={name}
                    required={required}
                    value={value}
                    min={0}
                    max={3}
                    step={1}
                    style={getBackgroundStyle()}
                />
                <div className="marker-content" style={{ left: `0%` }}>
                    <div className="marker"></div>
                    <p className="marker-text">0-1</p>
                </div>
                <div className="marker-content" style={{ left: `33.33%` }}>
                    <div className="marker"></div>
                    <p className="marker-text">1-3</p>
                </div>
                <div className="marker-content" style={{ left: `66.66%` }}>
                    <div className="marker"></div>
                    <p className="marker-text">3-5</p>
                </div>
                
                <div className="marker-content" style={{ left: `100%` }}>
                    <div className="marker"></div>
                    <p className="marker-text">5+</p>
                </div>
            </div>
        </div>
    );
};

export default InputRange;
