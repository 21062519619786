import React, { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SurveyService } from '../../api';
import Button from '../../components/Core/Button';
import { DataLoadingContext } from '../../components/Core/DataLoading';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import Typography from '../../components/Core/Typography';
import { SurveyApplicability, SurveyQuestionErrors, UserResponseQuestion, UserSurveyViewModel } from '../../models/Survey';
import QuestionCard from './QuestionCard';
import './survey.scss';
import SurveyStep from '../../components/Core/SurveyStepper';

const SurveyDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [survey, setSurvey] = React.useState<UserSurveyViewModel | null>();
    const [currentStep, setCurrentStep] = React.useState<number>(0);
    const [surveyErrors, setSurveyErrors] = React.useState<Array<SurveyQuestionErrors>>([]);
    const [updateDisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [loading, setLoading] = React.useState(false);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const[questionsList, setQuestionsList] = React.useState<UserResponseQuestion[]>([]);
    const history = useHistory();
    const location = useLocation();
    const myRef = useRef<null | HTMLDivElement>(null);
    React.useEffect(() => {
        if (id) {
            getSurvey();
        }
    }, [id]);

    // React.useEffect(()=>{
    //     if(survey)
    //     setCurrentStep(survey?.currentStep)
    // },[survey])

    React.useEffect(()=>{
        if(survey?.groupedQuestions){
        const questions: UserResponseQuestion[] = Object.values((survey as UserSurveyViewModel).groupedQuestions).flat(); 
        setQuestionsList(questions);
        }
    },[survey])

    const handleSubmit = () => {
        setLoading(true);
        let allErrors = checkSurveyForError();
        allErrors = allErrors.filter(
            (a) => a.identifier.length > 0 && (a.isApplicabilityError || a.isReviewerError || a.isYearsErrors),
        );
        setSurveyErrors(allErrors);

        if (allErrors.length === 0) {
            const newStep =
                currentStep + 1;

            const newUpdatedSurvey: UserSurveyViewModel = { ...(survey as UserSurveyViewModel), currentStep: newStep };
            updateSurvey(newUpdatedSurvey);
            if (newStep == newUpdatedSurvey.totalSteps) {
                history.push('/surveys');
            }
            setCurrentStep(newStep);
            setLoading(false);
        } else {
            setLoading(false);
            setUpdateDisabled(true);
        }
    };

    const checkSurveyForError = (): Array<SurveyQuestionErrors> => {
        const questions: UserResponseQuestion[] = Object.values((survey as UserSurveyViewModel).groupedQuestions).flat();  
        const allErrors: Array<SurveyQuestionErrors> = [];
       
            surveyErrorCheckerHelper(questions[currentStep] as UserResponseQuestion, (currentStep + 1).toString(), allErrors);
        
        return allErrors;
    };

    const surveyErrorCheckerHelper = (
        questions: UserResponseQuestion,
        identifier: string,
        errors: Array<SurveyQuestionErrors>,
    ): void => {
        let SurveyQuestionError: SurveyQuestionErrors = {
            isApplicabilityError: false,
            isReviewerError: false,
            isYearsErrors: false,
            identifier: '',
        };
        errors.push(SurveyQuestionError);

        SurveyQuestionError = errors[errors.length - 1];

        if (!questions.hasSubQuestions) {
            if (questions.doesExists === undefined || questions.doesExists === null) {
                SurveyQuestionError.isApplicabilityError = true;
                SurveyQuestionError.identifier = identifier;
            } else {
                if (questions.doesExists === SurveyApplicability.Yes && questions.needsCouncelorReview) {
                    if (!(questions.yearSinceLastReview?.length > 0)) {
                        SurveyQuestionError.isYearsErrors = true;
                        SurveyQuestionError.identifier = identifier;
                    }

                    let hasSelected = false;

                    questions.reviewers?.forEach((r) => {
                        if (r.isSelected) {
                            hasSelected = true;
                        }
                    });

                    if (!hasSelected) {
                        SurveyQuestionError.isReviewerError = true;
                        SurveyQuestionError.identifier = identifier;
                    }
                }
            }
        }

        const subQuestions = questions.metaData.subQuestions as UserResponseQuestion[];

        for (let i = 0; i < subQuestions.length; i++) {
            surveyErrorCheckerHelper(subQuestions[i] as UserResponseQuestion, `${identifier}.${i + 1}`, errors);
        }
    };

    const getSurvey = () => {
        toggleLoading(true);
        SurveyService.getSurveyViewModel(id === 'add-new' ? 'new' : id)
            .then((response) => {
                
                setCurrentStep(response.currentStep);
                setSurvey(response);
            })
            .finally(() => {
                toggleLoading(false);
            });
    };

    const updateSurvey = (updatedSurvey: UserSurveyViewModel) => {
        const isNewSurvey = location.pathname === '/surveys/add-new';
        SurveyService.updateSurveyViewModel(updatedSurvey, isNewSurvey? true: undefined)
            .then(() => {
                const isCompleted = updatedSurvey.currentStep === survey?.totalSteps;
                if (isNewSurvey && !isCompleted) {
                    // Update the URL without reloading the page
                    history.push(`/surveys/${survey?.id}`);
                }
                if (isCompleted) {
                    setTimeout(() => history.push(`/surveys`), 1000);
                }
                setSurvey({ ...(updatedSurvey as UserSurveyViewModel) });
            })
            .catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            })
            .finally(() => {
                setLoading(false);
                myRef.current?.scrollIntoView();
            });
    };
    function convertToGroupedQuestions(questions: UserResponseQuestion[]): Record<string, UserResponseQuestion[]> {
        const groupedQuestions: Record<string, UserResponseQuestion[]> = {};
      
        questions.forEach(question => {
            if(question.category){
          const groupKey = question.category.toLowerCase();
      
          if (!groupedQuestions[groupKey]) {
            groupedQuestions[groupKey] = [];
          }
      
          groupedQuestions[groupKey].push(question);
        }
        });
        return groupedQuestions;
      }

    const handleChange = (updatedQuestion: UserResponseQuestion, identifier: string) => {
        const tempArr = identifier.split('.'); 
        
        let questions = questionsList;
        tempArr.forEach((el, index) => { 
            const isLast = index === tempArr.length - 1;
            const indexEl = parseInt(el) - 1;
            if (isLast) {
                questions[indexEl] = updatedQuestion;
                const cquestions = questionsList;
                setSurvey({ ...(survey as UserSurveyViewModel), groupedQuestions: convertToGroupedQuestions(cquestions) });
            } else {
                questions = questions[indexEl].metaData.subQuestions
                    ? (questions[indexEl].metaData.subQuestions as UserResponseQuestion[])
                    : [];
            }
        });
        if (updateDisabled) {
            let allErrors = checkSurveyForError();
            allErrors = allErrors.filter(
                (a) => a.identifier.length > 0 && (a.isApplicabilityError || a.isReviewerError || a.isYearsErrors),
            );
            setSurveyErrors(allErrors);
            if (allErrors.length === 0) {
                setUpdateDisabled(false);
            }
        }
    };

    const getSurveyQuestions = () => {
        const tempSurveyQuestions: UserResponseQuestion[] = Object.values((survey as UserSurveyViewModel).groupedQuestions).flat();  
          return <div className="paper survey-question">
                    <div className="question">
                        <QuestionCard
                            question={tempSurveyQuestions[currentStep]}
                            identifier={(currentStep + 1).toString()}
                            handleChange={handleChange}
                            errorIdentifiers={surveyErrors}
                        />
                        </div>
                        {/* <div className="description-section hidden-tablet hidden-mobile">
                            <DescriptionCard question={tempSurveyQuestions[currentStep]} />
                        </div> */}
                    </div>
    };

    const handleSurveyBack = () => {
        setCurrentStep(currentStep - 1);
    };

    const getCategoriesFromSurvey = () =>{
        const categories = Object.keys((survey as UserSurveyViewModel).groupedQuestions).map((key, index) => {
            return {
              label: key,
              id: key,
              isCompleted: index === 0,
              isActive: index === 0,
            };
          });

          return categories;
    }

    function getActiveGroupAndStep(): { activeGroup: string; groupStep: number, totalQuestions: number } {
        let totalSteps = 0;
        const groupedQuestions =  (survey as UserSurveyViewModel).groupedQuestions;
        for (const groupKey in groupedQuestions) {
            const questionsInGroup = groupedQuestions[groupKey];
            totalSteps += questionsInGroup.length;       
            if (currentStep < totalSteps) {
              const groupStep = currentStep - (totalSteps - questionsInGroup.length);

              return {
                activeGroup: groupKey,
                groupStep: groupStep < 0 ? 0 : groupStep,
                totalQuestions: questionsInGroup.length,
              };
            }
          }
          const firstGroupKey = Object.keys(groupedQuestions)[0];
          const firstGroupQuestions = groupedQuestions[firstGroupKey];
          return {
            activeGroup: firstGroupKey,
            groupStep: 1,
            totalQuestions: firstGroupQuestions ? firstGroupQuestions.length : 0,
          };
      }
    return (
        <div className="survey-page" ref = {myRef}>
            <div className='step-container'>           
                {survey && <SurveyStep
                    categoryList={getCategoriesFromSurvey()}
                    activeStep={getActiveGroupAndStep().groupStep}
                    activeCategory={getActiveGroupAndStep().activeGroup}
                    total={ getActiveGroupAndStep().totalQuestions}
                />}
            <div>
            <div>
                <Typography variant="title" color="primary">
                    {survey?.title}
                </Typography>
                <Typography variant="subtitle">
                    {survey?.description}
                </Typography>
            </div>

            {survey && (
                <>
                    <div className="survey">{getSurveyQuestions()}</div>
                    <div className="action-container">
                        {currentStep >= 1 && (
                            <Button color="primary" onClick={handleSurveyBack}>
                                Prev
                            </Button>
                        )}
                        <Button disabled={updateDisabled} color="primary" onClick={handleSubmit} loading={loading}>
                            {currentStep + 1 >= survey.totalSteps ? `Submit` : `Next`}
                        </Button>
                    </div>
                </>
            )}
            </div>
        </div>
        </div>
    );
};

export default SurveyDetailPage;
