import React from 'react';
import { UserResponseQuestion } from '../../models/Survey';
import QuestionDetail from './QuestionDetail';

type Props = {
    question: UserResponseQuestion;
    height?: number | undefined;
};
const DescriptionCard: React.FC<Props> = ({ question }) => {
    const [modalQuestion, setModalQuestion] = React.useState<UserResponseQuestion | null>(null);



    const closeDetailModal = () => {
        setModalQuestion(null);
    };

    if (!question.detail || question.detail.length === 0) return null;
    return (
        <>
            {question.detail.length > 0 && (
                <div className="info-section">
                <div dangerouslySetInnerHTML={{ __html: question.detail }}></div>
                </div>
            )}
            {modalQuestion && (
                <QuestionDetail
                    showDetail={true}
                    title={modalQuestion.title}
                    detail={modalQuestion.detail}
                    closeModal={closeDetailModal}
                />
            )}
        </>
    );
};
export default DescriptionCard;
