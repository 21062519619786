import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ConsensusService } from "../../api";
import { AlertDialogActionType, AlertDialogContext } from "../../components/Core/AlertDialog/state";
import Button from "../../components/Core/Button";
import Typography from "../../components/Core/Typography";
import Consensus from "../../models/Consensus";
import {ReactComponent as BackIcon} from '../../assets/icons/arrow-left.svg';

type Props = {
    consensus:  Consensus;
    toggleView: () => void;
}

const ConsensusDetail: React.FC<Props> = ({consensus, toggleView}) =>{

    return consensus? (
        <>
        <div className="back-container">
            <Link to={"/employees"}><BackIcon/></Link>
        </div>
        <div className="consensus-detail paper">
            <ConsensusDetailCard consensus = {consensus} toggleView = {toggleView}/>
        </div>
        </>

    ) : (
        <></>
    );
}

export const ConsensusDetailCard: React.FC<{consensus: Consensus, toggleView: ()=> void}> = ({consensus, toggleView}) =>{
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const history = useHistory();

    const handleDelete = ()=>{
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload:{
                title: 'Delete Station',
                description: `Are you sure you want to delete ${consensus.firstName} ${consensus.lastName}?`,
                handleConfirm: ()=> confirmDelete(consensus.id as string)
            }
        });
    }
    
    const confirmDelete = (id: string) =>{
        ConsensusService.deleteConsensus(id).then(()=>{
            history.push("/employees");
        })
    }
    return (
        <>
            <br />
            <Typography variant="title" color="secondary">
                {consensus.firstName} {consensus.lastName}
            </Typography>
            <br />
            <div className="form-grid-half">
                <div>
                    <Typography variant = "body" color = "tertiary">
                        <strong>Personal Number:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.personalNumber}
                    </Typography>
                        <br/>
                    <Typography variant = "body" color = "tertiary">
                        <strong>Date of Birth:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.dob}
                    </Typography>
                        <br/>
                    <Typography variant = "body" color = "tertiary">
                        <strong>Date of Employment:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.doe}
                    </Typography>
                        <br/>
                    <Typography variant = "body" color = "tertiary">
                        <strong>Gender:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.gender}
                        </Typography>
                        <br/>                   
                    <Typography variant = "body" color = "tertiary">
                        <strong>Ethnicity:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.ethnicity}
                    </Typography>
                    <Typography variant="body">
                        <br/>                       
                    </Typography>  
                </div>
                <div>      
                    <Typography variant = "body" color = "tertiary">           
                        <strong>Job Title:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.jobTitle}
                    </Typography>
                        <br/>
                    <Typography variant = "body" color = "tertiary">
                        <strong>Clasification:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.classification}
                    </Typography>
                        <br/>
                    <Typography variant = "body" color = "tertiary">
                        <strong>Department:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.department}
                    </Typography>
                        <br/>
                    <Typography variant = "body" color = "tertiary">
                        <strong>State of Employment:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.state}
                    </Typography>
                        <br/>
                    <Typography variant = "body" color = "tertiary">
                        <strong>Supervisor:</strong>
                    </Typography>
                    <Typography variant="body">
                        {consensus.supervisor}
                    </Typography> 
                        <br/>   
                </div>
            </div>
                <br/> <hr/> <br/>
                <div className="form-grid-half">
                    <div>
                        <Typography variant = "body" color = "tertiary">
                            <strong>Base Salary:</strong>
                        </Typography>
                         <Typography variant="body">
                            ${consensus.baseSalary}                   
                        </Typography>
                        <br/>
                         <Typography variant="body" color = "tertiary">
                            <strong>Annual Bonus:</strong>
                        </Typography>
                         <Typography variant="body">
                            ${consensus.annualBonus}
                            <br/>
                        
                        </Typography>  
                    </div>
                    <div>      
                        <Typography variant = "body" color = "tertiary">            
                            <strong>Total Compensation:</strong>
                        </Typography>
                         <Typography variant="body">
                            ${consensus.totalCompensation}                        
                        </Typography>
                        <br/>
                         <Typography variant="body" color = "tertiary">
                            <strong>Total Cost:</strong>
                        </Typography>
                         <Typography variant="body">
                            ${consensus.totalCost}
                            <br/> 
                        </Typography> 
                    </div>                   
                </div>
                <br/> <hr/> <br/>
                {(consensus.classification==="Non-Extempt") &&
                    <>
                        <div className="form-grid-third">
                            <div>
                                <Typography variant = "body" color = "tertiary">            
                                    <strong>Hourly Rate:</strong>
                                </Typography>
                                <Typography variant = "body">
                                    ${consensus.hourlyRate}
                                </Typography> 
                                <br/>
                            </div>
                            <div>
                                <Typography variant = "body" color = "tertiary">            
                                    <strong>Regular Hours Worked:</strong>
                                    </Typography>
                                <Typography variant = "body">
                                    {consensus.regularHours}
                                </Typography> 
                                <br/>
                            </div>
                            <div>
                                <Typography variant = "body" color = "tertiary">            
                                    <strong>OverTime Hours Worked:</strong>
                                </Typography>
                                <Typography variant = "body">
                                    {consensus.otHours}
                                    <br/>
                                </Typography> 
                            </div>
                        </div>
                        <br/> <hr/> <br/>
                    </>
                }
                <div className="form-grid-half">
                <div>
                    <Typography variant = "body" color="tertiary">  
                            <strong>Offer Letter:</strong>
                        </Typography>
                        <Typography variant = "body">
                            {consensus.offerLetter}
                        </Typography>
                        <br/>
                        <Typography variant = "body" color="tertiary">  
                            <strong>Position Description:</strong>
                        </Typography>
                        <Typography variant = "body">
                            {consensus.positionDescription}
                        <br/>  
                    </Typography>  
                </div>
                <div>      
                    <Typography variant = "body" color="tertiary">            
                        <strong>IP/Non-Compete Policy Signed:</strong>
                    </Typography>
                    <Typography variant = "body">
                        {consensus.policySigned}
                    </Typography>
                    <br/>
                    <Typography variant = "body" color="tertiary">  
                        <strong>Handbook/Policy Binder Received and Signed:</strong>
                    </Typography>
                    <Typography variant = "body">
                        {consensus.handbooksRecieved}
                    <br/> 
                    </Typography> 
                </div>                   
                </div>
                <br/> <hr/> <br/>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body" color="tertiary">
                            <strong>Employee Perfomance Issues</strong>
                        </Typography>
                        <Typography variant="body">
                            {consensus.performanceIssues}
                        </Typography>
                    </div>
                    {(consensus.notes) &&
                        <div>
                            <Typography variant="body" color="tertiary">
                                <strong>Notes</strong>
                            </Typography>
                            <Typography variant="body">
                                {consensus.notes}
                            </Typography>
                        </div>                    
                    }
                </div>
                <br/><br/>
                <div className=" action-container">
                    <Button onClick={toggleView}> Edit </Button>
                    <Button onClick={handleDelete}>Delete</Button>
                </div>

        </>
    )
}

export default ConsensusDetail;