import React from 'react';
import { useParams } from 'react-router-dom';
import { UserService } from '../../api';
import Typography from '../../components/Core/Typography';
import { DataLoadingContext } from '../../components/Core/DataLoading';
import { SystemUser } from '../../models/User';
import { formatDate } from '../../utils';

const UserDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [user, setUser] = React.useState<SystemUser | null>(null);
    const toggleLoading = React.useContext(DataLoadingContext).toggleLoading;

    React.useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const getData = () => {
        toggleLoading(true);
        UserService.getUserById(id)
            .then((response) => setUser(response))
            .finally(() => toggleLoading(false));
    };
    return user ? (
        <div className="user-detail paper container-mobile">
            <UserDetailCard user={user} />
        </div>
    ) : (
        <></>
    );
};

export const UserDetailCard: React.FC<{ user: SystemUser }> = ({ user }) => {
    return (
        <>
            {user.isExisting && (
                <div className="client-identifier">
                    <p>Existing client</p>
                </div>
            )}
            <Typography variant="heading" color="secondary">
                {user.firstName} {user.lastName}
            </Typography>
            <br />
            <div className="form-grid-half">
                <div>
                    <Typography variant="body" color="primary" weight={600}>
                        Email Address
                    </Typography>
                    <Typography variant="body">
                        {user.emailAddress}
                    </Typography>
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body" color="primary" weight={600}>
                        Title
                    </Typography>
                    <Typography variant="body">
                        {user.title}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body" color="primary" weight={600}>
                        Industry
                    </Typography>
                    <Typography variant="body">
                        {user.industry}
                    </Typography>
                </div>
            </div>
            <br />
            <hr />
            <br />
            <div className="form-grid-half">
                <div>
                    <Typography variant="body" color="tertiary">
                        Last Login
                    </Typography>
                    <Typography variant="body">
                        {formatDate(user.dateCreated)}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body" color="tertiary">
                        Date Registered
                    </Typography>
                    <Typography variant="body">
                        {formatDate(user.lastLoginDate)}
                    </Typography>
                </div>
            </div>
            <br />
        </>
    );
};

export default UserDetail;
