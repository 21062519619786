import { AuthContext } from "../../containers/Authentication/state";
import React from "react";

 const getSteps = ()=> {
    const {user} = React.useContext(AuthContext).state;
    
    const steps = [
    {
        intro: `Welcome ${user?.name} to the tutorial.`,
        title: 'Tutorial'
    },
    {
        element: '.navbar',
        intro: 'This is the navigation bar',
        position: 'right'
    },
    {
      element: '#Survey',
      intro: 'This is the survey tab',
      position: 'right'
    },
    {
        intro: `This is survey page.`,
        title: 'Survey'
    },
    {
        element: '.button',
        intro: 'Click here to take a survey',
        position: 'bottom'
    },
    {
        intro: 'Skip for routing'
    },
    {
       intro: `This is survey page`,
       title: 'Survey'
   },
   {
    element: '.info-section',
    intro: 'This is the description of the question.',
    position: 'left'
   },
   {
       element: '.reviewed-by',
       intro: 'Check one of the options.',
       position: 'right'
      },
   {
   element: '.button',
   intro: 'Click here to submit a survey after answering the questions.',
   position: 'top'
   },
   {
       element: '#Employees',
       intro: 'Click here to view employees page',
       position: 'right'
   },
   {
        intro: 'Skip for routing'
    },
   {
       intro: `This is Employees page`,
       title: 'Employees'
   },
   {
       element: '.button',
       intro: 'Click here to add an employee',
       position: 'left'
   },
   {
        intro: 'Skip for routing'
    },
   {
       intro: `This is Employees Details Form`,
       title: 'Employees'
   },
   {
       element: '.input-field',
       intro: 'Type the employee details in the box.'
   },
            
   {
       element: '.submit',
       intro: 'Click here to submit after all the details are filled.',
       position: 'top'
   },
   {
       element: '#Blogs',
       intro: 'Click here to view blogs.',
       position: 'right'
   },  
   {
        intro: 'Skip for routing'
    },      
   {
       intro: `This is Blogs Page.`,
       title: 'Blogs'
   },
   {
       element: '#Faqs',
       intro: 'Click here to view a FAQ page.',
       position: 'right'
   },
   {
       intro: `This is FAQs Page.`,
       title: 'FAQs'
   },
   
];
 return steps;
}

export {getSteps}; 