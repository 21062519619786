import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import Typography from '../Core/Typography';
import './accountwrapper.scss';

const accountOptions = [
    { label: 'Profile', route: '/profile' },
    { label: 'Update Password', route: '/update-password' }
];
const AccountWrapper: React.FC = ({ children }) => {
    const [activeItem, setActiveItem] = React.useState('');

    const location = useLocation();

    React.useEffect(() => {
        const active = accountOptions.find((element) =>
            matchPath(location.pathname, {
                path: element.route,
            }),
        );
        if (active) {
            setActiveItem(active.label);
        }
    }, [location]);
    return (
        <div className="account-container">
            <div className="account-items">
                <div className="account-title">
                    <Typography variant="subheading" weight={300}>
                        Account
                    </Typography>
                </div>
                <div className="items-list">
                    {accountOptions.map((el, index) => (
                        <Link to={el.route} key={index}>
                            <div className={`item${activeItem === el.label ? ' active' : ''}`}>
                                <p>{el.label}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            {children}
        </div>
    );
};

export default AccountWrapper;
