import React from 'react';
import { SurveyService } from '../../api';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import Typography from '../../components/Core/Typography';

const SurveyCreate: React.FC<{ open: boolean; close: () => void }> = ({ open, close }) => {
    const [value, setValue] = React.useState('');

    const handleCreate = () => {
        if (value.length > 0) {
            SurveyService.createSurveyTemplate({
                name: value,
                description:
                    'Your participation in this evaluation is highly appreciated. Below, you&apos;ll find a comprehensive survey designed to assess various aspects of your HR department&apos;s compliance and effectiveness. Your responses will be kept confidential and will be used to identify areas of strength and opportunities for improvement within your HR practices.',
                isLatest: false,
                questions: [],
                totalSteps: 1,
            }).then(() => close());
        }
    };
    return (
        <Modal isOpen={open} title="CREATE SURVEY TEMPLATE" size="medium" close={close}>
            <Typography variant="body">Provide a name for the Survey:</Typography>
            <InputField name="name" value={value} onChange={(_name, value) => setValue(value)} />
            <ModalActionBar>
                <Button onClick={handleCreate}>CREATE</Button>
            </ModalActionBar>
        </Modal>
    );
};

export default SurveyCreate;
