import React from 'react';
import { ContentService } from '../../api';
import EditablePage from '../../components/EditablePage';
import Content, { ContentType } from '../../models/Content';
import CategorySelector from '../ContentManager/CategorySelector';
import BlogListTemplate from './BlogListTemplate';
import './blogs.scss';
import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';

interface PageState {
    skip: number;
    take: number;
}

const initialDataState: PageState = { skip: 0, take: 2};

const Blogs: React.FC = () => {
    const [contents, setContents] = React.useState<Content[]>([]);
    const [currentCategory, setCurrentCategory] = React.useState<string>('');
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const [total, setTotal] = React.useState(0);


    const pageChange = (event: PageChangeEvent) => {
        const pageNumber = (event.skip/event.take) + 1;
        setPage({...page, skip: event.skip, take: event.take});
        ContentService.getContents(ContentType.Blog, currentCategory, pageNumber, 3)
        .then((response) => {
            setContents(response.items);
            setTotal(response.total);
        });;
        
    };

    React.useEffect(() => {
        ContentService.getContents(ContentType.Blog, currentCategory,1,3)
        .then((response) => {
            setContents(response.items);
            setTotal(response.total);
        });
    }, [currentCategory]);

    const handleCategoryChange = (_name: string, value: string) => {
        setCurrentCategory(value);
    }

    return (
        <EditablePage>
            <div className='blogs-list-container'>
            <div className='blogs-grid'>
            <Pager
                skip={page.skip}
                take={page.take}
                total={total}
                buttonCount = {2}
                info = {false}
                previousNext={true}
                onPageChange={pageChange}
                responsive = {true}
                type = 'numeric'
            />
                <CategorySelector isList={true} category={currentCategory} type={ContentType.Blog} handleChange={(name, value) => handleCategoryChange(name, value)}/>
                </div>
                <div className="blogs">
                    {contents.map((content, index) => (
                        <BlogListTemplate content={content} key={index} />
                    ))}
                </div>

            </div>
        </EditablePage>
    );
};

export default Blogs;
