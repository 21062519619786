import React from 'react';
import Tooltip from '../Tooltip';
import './iconbutton.scss';

type Props = {
    Icon: React.FC;
    color?: string;
    onClick?: () => void;
    tooltip?: string;
};
const IconButton: React.FC<Props> = ({ Icon, color, tooltip, onClick }) => {
    return (
        <Tooltip text={tooltip}>
            <div
                className="icon-button"
                tabIndex={onClick?0:undefined}
                onClick={onClick}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && onClick && onClick()}
            >
                <div className={`icon ${color ? `color-${color}` : ''}`}>
                    <Icon />
                </div>
            </div>
        </Tooltip>
    );
};

export default IconButton;
