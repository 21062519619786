import React from 'react';
import './survey.scss';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import Button from '../../components/Core/Button';

interface Props{
    showDetail: boolean;
    title: string;
    closeModal: () => void;
    detail: string;
}

const QuestionDetail: React.FC<Props> = ({showDetail, closeModal, title, detail}) => {

    return(
        <Modal
            isOpen={showDetail}
            title={title}
            close={closeModal}
            size="large"
        >
            <div dangerouslySetInnerHTML={{ __html: detail }}></div>
            <ModalActionBar>
                <Button onClick={closeModal} color="secondary">
                    Close
                </Button>
            </ModalActionBar>
        </Modal>
    )
}

export default QuestionDetail;