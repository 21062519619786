import React from 'react';
import InputField from '../../components/Core/InputField';
import TextArea from '../../components/Core/TextArea';
import Typography from '../../components/Core/Typography';
import Content, { ContentConfig } from '../../models/Content';
import AuthorSelector from './AuthorSelector';
import CategorySelector from './CategorySelector';
import FeaturedImage from './FeaturedImage';

type Props = {
    handleChange: (name: string, value: string) => void;
    content: Content;
    config: ContentConfig
};
const ContentDetailForm: React.FC<Props> = ({ content, handleChange, config }) => {
    return (
        <div className="edit-content">
            <Typography variant="body">Title</Typography>
            <InputField name="title" value={content.title} onChange={handleChange} />
            {config.hasSubtitle && <>
                <Typography variant="body">Subtitle</Typography>
                <InputField name="subtitle" value={content.subtitle} onChange={handleChange} />
            </>}
            {config.hasSummary && <><Typography variant="body">Summary</Typography>
            <TextArea name="summary" value={content.summary} onChange={handleChange} /></>}
            {config.hasAuthor && <AuthorSelector handleAuthorSelection={(el)=>handleChange("authorId", el)} authorId={content.authorId}/>}
            {config.hasCategory && <CategorySelector category={content.category} handleChange={handleChange} type={content.type}/>}
            {config.hasFeaturedImage && <FeaturedImage handleChange={handleChange} source={content.featuredImage}/>}
        </div>
    );
};

export default ContentDetailForm;
