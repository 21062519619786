import React from 'react';
import { useWindowDimensions } from '../../../utils';
import Typography from '../Typography';
import StepperMark from './StepperMark';
import './surveystepper.scss';
type Props = {
    activeCategory: string;
    activeStep: number;
    total: number;
    categoryList: Category[]
};
type Category = {
    id: string;
    label: string;
    isCompleted: boolean;
    isActive: boolean;
};

// const initCategories: Category[] = [
//     {
//         label: 'Roles and Responsibilities',
//         id: 'R',
//         isCompleted: true,
//         isActive: true,
//     },
//     {
//         label: 'Compensation',
//         id: 'C',
//         isCompleted: false,
//         isActive: false,
//     },
//     {
//         label: 'Opportunity Cost',
//         id: 'O',
//         isCompleted: false,
//         isActive: false,
//     },
// ];

const SurveyStep: React.FC<Props> = ({ activeCategory, total, activeStep, categoryList }) => {
    const [categories, setCategories] = React.useState<Category[]>(categoryList);
    const { width } = useWindowDimensions();
    React.useEffect(() => {
        const temp = [...categoryList];
        const activeIndex = categoryList.findIndex((el) => el.label === activeCategory);
        if (activeIndex !== -1) {
            temp.forEach((el, index) => {
                if (index < activeIndex) {
                    el.isCompleted = true;
                }
                if (index === activeIndex) {
                    el.isActive = true;
                } else {
                    el.isActive = false;
                }
            });
        }     
        setCategories([...temp]);
    }, [activeCategory, total, activeStep]);

    const getSubItems = () => {
        return (
            <>
                {Array.from(Array(total).keys()).map((el) => (
                    <div className={`stepper-subitems${activeStep >= el ? ' completed' : ''}`} key={el}>
                        <StepperMark isCategory={false} isCompleted={activeStep >= el} />
                    </div>
                ))}
            </>
        );
    };

    return (
        <div className="survey-stepper">
            {width> 768 ? <>{categories.map((el, index) => (
                <div key={index} className="stepper-step">
                    <div className={`stepper-category`}>
                        <StepperMark isCategory={true} isCompleted={el.isCompleted || el.isActive} />
                        <p className={`category-label${el.isCompleted ? ' completed' : ''}`}>{el.label}</p>
                    </div>
                    {!el.isActive && index + 1 !== categories.length && (
                        <div className={`inactive-category-line ${el.isCompleted ? ' completed' : ''}`}></div>
                    )}
                    {el.isActive && getSubItems()}
                </div>
            ))}
            </>: <div className="stepper-mobile">
                    <Typography variant='heading' weight={600} color="secondary">{activeStep+1}/{total}</Typography>
                    <Typography variant='title' weight={500} color="primary">{activeCategory}</Typography>
                </div>}
        </div>
    );
};

export default SurveyStep;
