import React, { ReactElement } from 'react';
import { useWindowDimensions } from '../../utils';
import IconButton from '../Core/IconButton';
import { ReactComponent as LeftArrow } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import HorizontalNav from './HorizontalNav';
import Logo from '../../assets/images/hires_logo.png';
import VerticalNav from './VerticalNav';
import { Link, matchPath, useLocation } from 'react-router-dom';
import config from '../../config';
import { Route } from '../../config/models';
import './layout.scss';
import AccountActions from './AccountActions';
import DataLoading from '../Core/DataLoading';
import Typography from '../Core/Typography';

type Props = {
    children: ReactElement;
};
const Layout: React.FC<Props> = ({ children }) => {
    const [isNavOpen, setIsNavOpen] = React.useState(false);
    const [isNavMin, setNavMin] = React.useState(false);
    const { width } = useWindowDimensions();
    const [activeRoute, setActiveRoute] = React.useState<null | Route>(null);
    const location = useLocation();

    React.useEffect(() => {
        const active = config.routes.find((element) =>
            matchPath(location.pathname, {
                path: element.route,
            }),
        );
        if (active) {
            setActiveRoute(active);
        } else {
            setActiveRoute(null);
        }
    }, [location]);

    React.useEffect(() => {
        if (activeRoute) {
            if (width < 768) {
                setIsNavOpen(false);
                setNavMin(false);
            }
            if (width >= 768 && !activeRoute.showVerticalNav) {
                setIsNavOpen(false);
            }
            if (width >= 768 && width < 1280 && activeRoute.showVerticalNav) {
                setIsNavOpen(true);
                setNavMin(true);
            }
            if (width >= 1280 && activeRoute.showVerticalNav) {
                setIsNavOpen(true);
                setNavMin(false);
            }
        } else {
            setIsNavOpen(false);
        }
    }, [activeRoute, width]);

    const handleToggleMenu = () => {
        if (width <= 768) {
            setIsNavOpen(!isNavOpen);
        } else {
            setNavMin(!isNavMin);
        }
    };

    return (
        <div className="layout-container">
            {activeRoute && (
                <>
                    {((width <= 768 && (activeRoute.showHorizontalNav || activeRoute.showVerticalNav)) ||
                        isNavOpen) && (
                        <div className="nav-bar-toggle">
                            <IconButton
                                Icon={
                                    width < 768 ? (isNavOpen ? CloseIcon : MenuIcon) : isNavMin ? RightArrow : LeftArrow
                                }
                                onClick={handleToggleMenu}
                                color="white"
                            />
                        </div>
                    )}
                    {isNavOpen && (
                        <div className={`nav-bar-vertical-container ${isNavMin ? 'nav-min' : 'nav-max'}`}>
                            <VerticalNav
                                activeRoute={activeRoute}
                                isMinimized={isNavMin}
                                closeNav={() => {
                                    width < 768 && setIsNavOpen(false);
                                }}
                            />
                            <div className="logo-container">
                                <img
                                    src={Logo}
                                    alt="Logo"
                                    className={isNavMin ? 'minified' : 'maximized'}
                                />
                                {!isNavMin && <Typography variant='body' weight={600} textAlign='center'>HIRES</Typography>}
                            </div>
                        </div>
                    )}
                    <div className={`page-container ${activeRoute.class ? activeRoute.class : ''}${isNavOpen? isNavMin?' nav-min-open': ' nav-max-open':''}`}>
                        {(activeRoute.showHorizontalNav || activeRoute.showVerticalNav) && (
                            <div className="nav-bar-horizontal-container">
                                <div className="navbar">
                                    <div className='navbar-items'>
                                        {!activeRoute.showVerticalNav && (
                                            <div className="top-logo">
                                                <Link to="/">
                                                    <img src={Logo} alt="logo" />
                                                </Link>
                                            </div>
                                        )}
                                        {width >= 768 && activeRoute.showHorizontalNav && (
                                            <HorizontalNav activeItem={activeRoute.label} />
                                        )}
                                    </div>
                                    {activeRoute.showHorizontalNav && <AccountActions/>}
                                </div>
                            </div>
                        )}
                        <DataLoading>
                            <div className="page-content">
                                <div className="container">{children}</div>
                            </div>
                        </DataLoading>
                    </div>
                </>
            )}
        </div>
    );
};

export default Layout;
