import React from 'react';
import './forgotpassword.scss';
import Logo from '../../assets/images/hires_logo.png';
import Typography from '../../components/Core/Typography';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import { Link } from 'react-router-dom';
import { emailIsValid } from '../../utils';
import { AuthService } from '../../api';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = React.useState('');
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleEmailChange = () => {
        setIsSuccess(false);
        setError('');
    };

    const handleResetPassword = () => {
        setIsLoading(true);
        AuthService.forgotPassword(email)
            .then(() => {
                setIsLoading(false);
                setIsSuccess(true);
                setError('');
            })
            .catch((e) => {
                setIsLoading(false);
                setError(e.message);
            });
    };

    return (
        <div className="page forgot-password-page">
            <section>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                <Typography variant="heading" weight={600} textAlign="center">
                    CORSIGHT
                </Typography>
                <br/>
                {!isSuccess ? (
                    <>
                        <Typography variant="subheading" color="secondary">
                            FORGOT PASSWORD
                        </Typography>
                        <Typography variant="body">
                            Enter the email associated with your account and {`we'll`} send an email with instructions
                            to reset your password.
                        </Typography>
                        {error.length > 0 && (
                            <Typography variant="body" color="error">
                                {error}
                            </Typography>
                        )}
                        <br />
                        <InputField
                            onChange={(_name, value) => setEmail(value)}
                            name="email"
                            variant="outline"
                            outlineLabel="Email Address"
                            required={true}
                            value={email}
                            error={email.length > 0 && !emailIsValid(email)}
                        />
                        <div className="action-container">
                            <Typography variant="caption">
                                <Link to="/">
                                    <span className="text-action">Back to login</span>
                                </Link>
                            </Typography>
                            <Button color="primary" onClick={handleResetPassword} disabled={!emailIsValid(email)}>
                                Send Instructions
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <Typography variant="title" color="tertiary" textAlign="center">
                            We have sent a password recover instruction to your email.
                        </Typography>
                        <Typography variant="body" textAlign="center">
                            Did not receive the email? Check your spam filter, or&nbsp;
                            <strong
                                onClick={handleEmailChange}
                                onKeyPress={(e: React.KeyboardEvent) => e.key === 'Enter' && handleEmailChange()}
                                className="text-action"
                                tabIndex={0}
                            >
                                try another email address
                            </strong>
                        </Typography>
                        <Link to="/">
                            {' '}
                            <Button color="primary" className="mx-auto d-block" loading={isLoading}>
                                Back to Login
                            </Button>
                        </Link>
                    </>
                )}
            </section>
        </div>
    );
};

export default ForgotPassword;
