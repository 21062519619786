import React from 'react';
import IconButton from '../../components/Core/IconButton';
import InputField from '../../components/Core/InputField';
import TextArea from '../../components/Core/TextArea';
import Typography from '../../components/Core/Typography';
import { initQuestion, Question } from '../../models/Survey';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as UnExpandIcon } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as UpIcon } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/arrow-down.svg';
import ContentEditor from '../Editor';
import Checkbox from '../../components/Core/Checkbox';
import Button from '../../components/Core/Button';
import Toggle from '../../components/Core/Toggle';
import { SurveyService } from '../../api';
import InputSelect from '../../components/Core/InputSelect';
import { getOptions } from './SurveyTemplateDetail';

type Option = {
    label: string;
    value: string;
};

type Props = {
    question: Question;
    handleApplyChanges: (updatedQuestion: Question, identifier?: string) => void;
    handleRemove: () => void;
    identifier: string;
    open: string;
    setOpen: (value: string) => void;
    invalidId: string;
    handleReorder: (identifier: string, direction: 'up' | 'down') => void;
    totalSiblings: number;
    categoryOptions: Option[];
    
};

const BuilderQuestionCard: React.FC<Props> = ({
    question,
    handleApplyChanges,
    handleRemove,
    identifier,
    open,
    setOpen,
    invalidId,
    handleReorder,
    totalSiblings,
    categoryOptions
}) => {
    const [newQuestion, setNewQuestion] = React.useState(question);
    const [categories, setCategories]= React.useState<Option[]>(categoryOptions);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        setNewQuestion(question);
    }, [question]);


    React.useEffect(()=>{
        setCategories(categoryOptions);
    },[categoryOptions])

    // React.useEffect(()=>{
    //     getCategories();
    // },[])

    // const getOptions = (categories: SurveyCategory[])=>{
    //     const options: Option[] = categories.map((category) => ({
    //         label: category.category,
    //         value: category.category,
    //     }));

    //     return options;
    // }

    // const getCategories = () => {
    //     SurveyService.getAllCategories().then((res)=> {
    //         setCategories(getOptions(res));
    //     });
    // }
    
    const handleCreate = (category: string) => {       
        SurveyService.addCategory(category).then((res)=>{
            setCategories(getOptions(res));
        }).finally(()=>{
            handleCategorySelection('category', category);
        })
    }

    const handleChange = (name: string, value: string | boolean | number) => {
        setNewQuestion({ ...newQuestion, [name]: value });
    };

    const handleCategorySelection = (name: string, value: string) => {
        const updateSubquestions = (questions: Question[]): Question[] => {
          const quest = questions.map((subQuestion) => {
            if (subQuestion.metaData.subQuestions && subQuestion.metaData.subQuestions.length>0) {
              return { ...subQuestion,[name]: value , metaData: {subQuestions : updateSubquestions(subQuestion.metaData.subQuestions as Question[])} };
            } else {
              return { ...subQuestion, [name]: value };
            }
          });
          return quest;
        };
      
        setNewQuestion((prevQuestion) => {
          if (prevQuestion.metaData.subQuestions && prevQuestion.metaData.subQuestions.length>0) {
            const newQuestion =  { ...prevQuestion, [name]: value , metaData: {subQuestions: updateSubquestions(prevQuestion.metaData.subQuestions as Question[])} };
            return newQuestion;
          } else {
            return { ...prevQuestion, [name]: value };
          }
        });
      };
      

    const handleErrorChange = (name: string, value: string | boolean | number) => {
        const response = newQuestion.errorResponse;
        const temp = { ...response, [name]: value };
        setNewQuestion({ ...newQuestion, errorResponse: temp });
    };

    const handleToggleSubquestion = (_name: string, value: boolean) => {
        setNewQuestion({
            ...newQuestion,
            hasSubQuestions: value,
            weight: undefined,
            metaData:  {},
            failsParentQuestion: false,
        });
    };

    const handleAddSubQuestion = () => {

        if (newQuestion.title.length > 0 && newQuestion.category != undefined) {
            let temp = { ...newQuestion };
            const newSubQuestion ={...initQuestion, category: newQuestion.category}
            const subQuestions = temp.metaData.subQuestions
                ? [...temp.metaData.subQuestions as Question[], newSubQuestion]
                : [newSubQuestion];       
            temp = {
                ...temp,
                metaData: { subQuestions: subQuestions },
            };
            if(open!== identifier){
                setOpen(identifier);
                handleApplyChanges(temp, identifier);
            }
            else{
            handleApplyChanges(temp);
            }
            setOpen(`${identifier}.${subQuestions.length}`);
        }
    };

    const applyChanges = () => {
        setShowError(false);
        const isWeightValid = (newQuestion.weight)? (newQuestion.weight>0) : false;
        console.log(isWeightValid);
        console.log(newQuestion);
        const isMetaDataValid = (newQuestion.metaData.subQuestions ) ? newQuestion.metaData.subQuestions.length > 0 : (newQuestion.weight && newQuestion.weight>0);
        console.log(isMetaDataValid);
        console.log(newQuestion.title.length === 0 );
        console.log(newQuestion.category === undefined);
        if(!isMetaDataValid || newQuestion.category === undefined || newQuestion.title.length === 0 || !isWeightValid){
            setShowError(true);
        }
        else{
            handleApplyChanges(newQuestion);
        }
    }

    const getSubquestionTotal = (question: Question): number => {
        const subs = question?.metaData?.subQuestions ?? [];
        let total = 0;
        subs.forEach((el) => {
            if (el.hasSubQuestions && el.metaData.subQuestions && el.metaData.subQuestions?.length > 0) {
                total += getSubquestionTotal(el);
            } else {
                total += parseFloat(`${el.weight}`) ?? 0;
            }
        });
        return total;
    };

    const closeQuestion = (value: string): string => {
        const parts = value.split('.');

        // Check if there is more than one part (i.e., if there is a dot in the input)
        if (parts.length > 1) {
            // Remove the last item (including the dot) and join the remaining parts with dots
            return parts.slice(0, -1).join('.');
        } else {
            // If there is only one part or no dot, return the input string as is
            return '';
        }
    };

    const isFirstSibling =():boolean=> {
        const parts = identifier.split('.'); 
        const index = parseInt(parts[parts.length - 1]);
        if(index === 1) return true;
        return false;
    }

    const isLastSibling =():boolean=> {
        const parts = identifier.split('.'); 
        const index = parseInt(parts[parts.length - 1]);
        if(index === totalSiblings) return true;
        return false;
    }
    function expandId(invalidIdentifier:string) {
        const ids = invalidIdentifier.split('.');
        const result = [];
        let current = "";
    
        for (const id of ids) {
            if (current !== "") {
                current += ".";
            }
            current += id;
        
            result.push(current);
        }
    
        return result;
    }
    
    let isSubquestion;
    identifier.includes('.') ? (isSubquestion = true) : (isSubquestion = false);
    const showDetails = open.length > 0 && open.includes(identifier);
    return (
        <div className="question-container">
            <div className={`question-card${(expandId(invalidId).includes(identifier))  ? ' show-error' : ''}`} id={identifier}>
                <div className="card-header">
                    <div>
                        <Typography variant="subtitle" color="secondary">
                            {identifier}. {question.title}
                        </Typography>
                        <Typography variant="caption" weight={800}>
                            {newQuestion.hasSubQuestions ? getSubquestionTotal(newQuestion) : question.weight ?? 0}{' '}
                            Point(s){' '}
                            {newQuestion.hasSubQuestions && newQuestion.metaData.subQuestions && (
                                <span>
                                    &nbsp;&nbsp;|&nbsp;&nbsp; {newQuestion.metaData.subQuestions.length} subquestion(s)
                                </span>
                            )}
                        </Typography>
                    </div>
                    <div className="card-actions">
                        {showDetails ? (
                            <>
                                {(newQuestion.hasSubQuestions) && (
                                    <IconButton
                                        Icon={AddIcon}
                                        color="info"
                                        tooltip="Add subquestion"
                                        onClick={handleAddSubQuestion}
                                    />
                                )}
                                <IconButton Icon={DeleteIcon} color="error" tooltip="Delete" onClick={handleRemove} />
                            </>
                        ) : (
                            <>
                                <IconButton
                                    Icon={UpIcon}
                                    color={!isFirstSibling() ? "info": "grey"}
                                    onClick={() => !isFirstSibling() && handleReorder(identifier, "down")}
                                />
                                <IconButton
                                    Icon={DownIcon}
                                    color={!isLastSibling() ? "info": "grey"}
                                    onClick={() => !isLastSibling() && handleReorder(identifier, "up")}
                                />
                            </>
                        )}
                        <IconButton
                            Icon={showDetails ? UnExpandIcon : ExpandIcon}
                            color="info"
                            onClick={() => setOpen(showDetails ? closeQuestion(identifier) : identifier)}
                        />
                    </div>
                </div>
                {showDetails && (
                    <div className="question-form">
                        <Typography variant="body">Title</Typography>
                        <InputField
                            name="title"
                            value={newQuestion.title}
                            error={showError && newQuestion.title.length === 0}
                            errorText="Required"
                            onChange={handleChange}
                        />
                        
                        {!isSubquestion && <InputSelect
                        onChange={handleCategorySelection}
                        key = {newQuestion.category}
                        value={newQuestion.category}
                        name = "category"
                        options = {categories}
                        placeholder= "Category"
                        handleCreate={handleCreate}        
                        error = {showError && newQuestion.category === undefined}
                        errorText='Required'               
                        /> 
                        }
                        
                        {!isSubquestion && (
                            <>
                                <Typography variant="body">Description</Typography>
                                <br></br>{' '}
                                <ContentEditor
                                    name="detail"
                                    height={300}
                                    value={question.detail}
                                    onChange={handleChange}
                                />
                            </>
                        )}
                        <Typography variant="body">Has Subquestions</Typography>
                        <Typography variant="caption">
                            <i>*Will remove existing subquestions if unselected</i>
                        </Typography>
                        <Toggle
                            name="hasSubQuestions"
                            value={newQuestion.hasSubQuestions}
                            onChange={handleToggleSubquestion}
                        />
                        {newQuestion.hasSubQuestions &&
                            (showError && !(newQuestion.metaData.subQuestions && newQuestion.metaData.subQuestions.length > 0)) && (
                                <Typography variant="caption" color="error">
                                    Please add subquestions
                                </Typography>
                            )}
                        {!newQuestion.hasSubQuestions && (
                            <>
                                <div className="form-grid-half">
                                    <div>
                                        <Typography variant="body">Question Weight:</Typography>
                                        <InputField
                                            name="weight"
                                            value={newQuestion.weight ? newQuestion.weight : ''}
                                            type="number"
                                            onChange={handleChange}
                                            error={newQuestion.weight ? (showError && newQuestion.weight <= 0) : showError}
                                            errorText="Please provide a valid weight."
                                        />
                                    </div>
                                </div>
                                <div className="form-grid-third">
                                    <div>
                                        <Checkbox
                                            checked={newQuestion.needsCouncelorReview}
                                            label="Needs Attorney Review"
                                            onChange={() =>
                                                handleChange('needsCouncelorReview', !newQuestion.needsCouncelorReview)
                                            }
                                        />
                                    </div>
                                    {identifier.split('.').length > 1 && (
                                        <div>
                                            <Checkbox
                                                checked={newQuestion.failsParentQuestion}
                                                label="Fails parent question if incorrect"
                                                onChange={() =>
                                                    handleChange(
                                                        'failsParentQuestion',
                                                        !newQuestion.failsParentQuestion,
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <Checkbox
                                            checked={newQuestion.failsExam}
                                            label="Fails exam if incorrect"
                                            onChange={() => handleChange('failsExam', !newQuestion.failsExam)}
                                        />
                                    </div>
                                </div>
                                <div className="form-grid-third">
                                    <div>
                                        <Typography variant="body">Error for No</Typography>
                                        <TextArea
                                            name="errorByNo"
                                            value={newQuestion.errorResponse.errorByNo}
                                            onChange={handleErrorChange}
                                        />
                                    </div>
                                    <div>
                                        <Typography variant="body">Error for NA</Typography>
                                        <TextArea
                                            name="errorByNA"
                                            value={newQuestion.errorResponse.errorByNA}
                                            onChange={handleErrorChange}
                                        />
                                    </div>
                                    <div>
                                        <Typography variant="body">Error for Unknown</Typography>
                                        <TextArea
                                            name="errorByUnknown"
                                            value={newQuestion.errorResponse.errorByUnknown}
                                            onChange={handleErrorChange}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {open == identifier &&
                        <div className="action-container">
                        
                            <Button color="primary" onClick={applyChanges}>
                                Apply Changes
                            </Button>
                            
                        </div>
                        }
                    </div>
                )}
            </div>
            {showDetails &&
                newQuestion.metaData.subQuestions &&
                newQuestion.metaData.subQuestions.map((subQuestion, index) => (
                    <BuilderQuestionCard
                        question={subQuestion}
                        handleApplyChanges={handleApplyChanges}
                        handleRemove={handleRemove}
                        identifier={`${identifier}.${index + 1}`}
                        open={open}
                        setOpen={setOpen}
                        invalidId={invalidId}
                        handleReorder={handleReorder}
                        totalSiblings={(newQuestion.metaData.subQuestions as Question[]).length}
                        key={`${identifier}-${index}-${new Date()}`}
                        categoryOptions={categories}
                    />
                ))}
        </div>
    );
};

export default BuilderQuestionCard;
