import React from 'react';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import Typography from '../../components/Core/Typography';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import { AuthActionType, AuthContext } from '../Authentication/state';
import { AuthService } from '../../api';

import { Link, useHistory } from 'react-router-dom';

type login = {
    emailAddress: string;
    password: string;
};

const Login: React.FC = () => {
    const history = useHistory();
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const authDispatch = React.useContext(AuthContext).dispatch;
    const [isLoading, setIsLoading] = React.useState(false);

    const initLogin: login = {
        emailAddress: '',
        password: '',
    };

    const [login, setLogin] = React.useState(initLogin);

    const handleLogin = () => {
        setIsLoading(true);
        AuthService.login(login.emailAddress, login.password)
            .then((response) => {
                new Promise((resolve) => {
                    localStorage.setItem('hiring-token', response.accessToken);
                    authDispatch({ type: AuthActionType.LOGIN, payload: response });
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: `Welcome back ${response.name}!`,
                            status: 'info',
                            autoClose: true,
                        },
                    });
                    setIsLoading(false);
                    resolve('OK');
                }).then(() => {
                    setIsLoading(false);
                    history.push('/surveys');
                });
            })
            .catch((error) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text:
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString(),
                        status: 'error',
                        autoClose: true,
                    },
                });
                setIsLoading(false);
            });
    };
    const handleChange = (name: string, value: string): void => {
        setLogin({ ...login, [name]: value });
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
            }}
        >
            <Typography variant="title" weight={600} color="primary">
                LOGIN
            </Typography>
            <Typography variant="subtitle">
                Email Address
            </Typography>
            <InputField onChange={handleChange} name="emailAddress" type="email" required={true} />
            <Typography variant="subtitle">
                Password
            </Typography>
            <InputField onChange={handleChange} name="password" type="password" required={true} />
            <div className="action-container">
                <Typography variant="body">
                    <Link to="/forgot-password">
                        <span className="text-action">Forgot Password?</span>
                    </Link>
                    <br />
                </Typography>
                <Button loading={isLoading} type="submit" textColor="white">
                    Login
                </Button>
            </div>
        </form>
    );
};

export default Login;
