import React from 'react';
import { ContentService } from '../../api';
import { ContentType } from '../../models/Content';
import CreatableSelect from 'react-select/creatable';
import Typography from '../../components/Core/Typography';

type Props = {
    handleChange: (name:string, value: string)=>void;
    type: ContentType;
    category?: string;
    isList?: boolean;
}
const CategorySelector:React.FC<Props>=({type, handleChange, category, isList})=> {
    const [categories, setCategories]= React.useState<{label: string, value: string}[]>([]);


    React.useEffect(()=> {
        getData();
    }, []);

    const getData=()=> {
        ContentService.getCategories(type).then((response)=> {
            if (type === ContentType.Blog && isList === true) { response.unshift({label: 'All', value: ''}) };
            setCategories(response);
        });
    }
    return <div className='category-selector'>
        <Typography variant="body">Category</Typography>
        <CreatableSelect options={categories} onChange={el=>handleChange("category", (el as {label:string, value:string}).value)} value={category ? {label: category, value: category}: undefined} placeholder={isList?"Filter by Category": "Select Category"} />
    </div>
}

export default CategorySelector;