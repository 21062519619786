import React from 'react';
import Select, { SingleValue } from 'react-select';
import './select.scss';
type Option = {
    label: string;
    value: string;
};

type Props = {
    onChange: (name: string, value: string) => void;
    name: string;
    value?: string | null;
    options: Array<Option>;
    placeholder: string;
    error?: boolean;
    errorText?:string;
    handleCreate?: (val: string)=> void;
    disabled?: boolean
};

const InputSelect: React.FC<Props> = ({ name, value='', options, placeholder, onChange, error, errorText, handleCreate, disabled }) => {
    const [selected, setSelected] = React.useState<Option | undefined | null>();

    React.useEffect(() => {
        const newValue = options.find((element: Option) => element.value === value);
        setSelected(newValue);
    }, [value]);

    const handleChange = (option: SingleValue<Option>): void => {
        if (option) {
            onChange(name, option.value);
        }
    };
    const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
        if (handleCreate && inputValue.length>0) {
            return (
                <div onClick={() => handleCreate(inputValue)} style={{cursor: 'pointer'}}>
                    Create new &quot;{inputValue}&quot;
                </div>
            );
        } else {
            return 'No options found';
        }
    };

    return (
        <div className="select-container">
            <Select
                onChange={handleChange}
                name={name}
                options={options}
                value={selected}
                placeholder={placeholder}
                classNamePrefix={error?  'error-select' : 'input-select'}
                noOptionsMessage={noOptionsMessage}
                isDisabled = {disabled}
            />
            {error && <p className="error-text">{errorText}</p>}
        </div>
    );
};

export default InputSelect;
