export default interface SurveyTemplate {
    id?: string;
    name: string;
    description?: string;
    isLatest: boolean;
    createdAt?: string;
    questions: Question[];
    totalSteps: number;
}

export interface SurveyCategory {
    id: string;
    category:string;
    createdAt: string;
}

export interface UserSurvey {
    id: string;
    title: string;
    description?: string;
    userId: string;
    questions: UserResponseQuestion[];
    totalSteps: number;
    currentStep: number;
    totalScore: number;
    finalScore: number;
    updatedAt: string;
    grade: string;
}

export interface UserSurveyViewModel {
    id: string;
    userId: string;
    title: string;
    description: string;
    groupedQuestions: Record<string, UserResponseQuestion[]>;
    totalSteps: number;
    currentStep: number;
    totalScore: number;
    finalScore: number;
    updatedAt: Date;
    grade: string;
}

export interface UserSurveyGrid {
    surveyId: string;
    userId: string;
    name: string;
    emailAddress: string;
    finalScore: number;
    totalScore: number;
    totalSteps: number;
    currentStep: number;
    updatedAt: string;
    grade: string;
}


export interface Question {
    title: string;
    detail:string;
    position: number;
    weight?: number;
    hasSubQuestions: boolean;
    failsExam: boolean;
    needsCouncelorReview: boolean;
    failsParentQuestion: boolean;
    reviewers: Reviewer[];
    metaData: QuestionMetaData;
    errorResponse: ErrorResponse;
    category?: string;
}

export interface ErrorResponse {
    errorByNo: string;
    errorByNA: string;
    errorByUnknown: string;
    errorByCounselor?: string;
    errorByYears?: string;
}
export interface UserResponseQuestion extends Question {   
    doesExists?: SurveyApplicability;
    yearSinceLastReview: string;
}

export interface SurveyQuestionErrors {
    isApplicabilityError: boolean;
    isYearsErrors: boolean;
    isReviewerError: boolean;
    identifier: string;
}

export type Reviewer = {
    label:string;
    value: string;
    isSelected? : boolean;
}

export type QuestionMetaData = {
    subQuestions?: Question[] | UserResponseQuestion[]
}

export enum SurveyApplicability{
    Yes = 1,
    No = 0,
    NA = 2,
    Unknown = 3
}

const initReviewers: Reviewer[] = [
    {
        label: 'Attorney',
        value: 'Attorney',
    },
    {
        label: 'PEO',
        value: 'PEO',
    },
    {
        label: 'Consultant',
        value: 'Consultant',
    },
    {
        label: 'Internal/HR',
        value: 'InternalHR',
    },
    {
        label: 'None',
        value: 'None',
    },
];

export const initQuestion: Question = {
    title: '',
    detail: '',
    position: 0,
    hasSubQuestions: true,
    failsExam: false,
    failsParentQuestion: false,
    needsCouncelorReview: false,
    reviewers: initReviewers,
    metaData: {},
    errorResponse: {
        errorByNo: 'It is important that you have this policy in place to ensure compliance.',
        errorByNA: 'Since this question is not applicable to your situation, you can proceed to the next question.',
        errorByUnknown: 'Collecting this information is crucial for a more accurate evaluation.',
    }
};


export type ReportTemplatePage = {
    newCoverPageUrl?: string;
    oldCoverPageUrl?:string;
    newBackPageUrl?: string;
    oldBackPageUrl?: string;
}