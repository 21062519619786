import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthActionType, AuthContext } from '../../containers/Authentication/state';
import { UserRole } from '../../models/User';
import Button from '../Core/Button';
import Menu, { MenuItem } from '../Core/Menu';

const AccountActions: React.FC = () => {
    const { isLoggedIn, user } = React.useContext(AuthContext).state;
    const dispatch = React.useContext(AuthContext).dispatch;
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
    const ProfileRef = React.useRef<HTMLDivElement>(null);
    const history = useHistory();

    const handleNavigation = (route: string, state?: unknown) => {
        history.push(route, state);
    };

    return (
        <>
            {!isLoggedIn && (
                <div className="account-actions">
                    <Link to="/register">
                        <Button>Get Started</Button>
                    </Link>
                </div>
            )}
            {isLoggedIn && (
                <div className="account-actions">
                    {user && (
                        <div className="profile-section">
                             <div
                                onClick={() => setProfileMenuOpen(true)}
                                tabIndex={0}
                                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                    e.key === 'Enter' && setProfileMenuOpen(true)
                                }
                                ref={ProfileRef}
                    >
                                <p>
                                    {user?.name}
                                    <br />
                                    {user?.role === UserRole.Admin && <span className="user-role">ADMIN</span>}
                                </p>
                            </div>
                            <span className="caret-down">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#FFFFFF">
                                    <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                                </svg>
                            </span>
                        </div>
                    )}
                </div>
            )}
            <Menu open={profileMenuOpen} elementRef={ProfileRef} close={() => setProfileMenuOpen(false)} width={120}>
                <MenuItem
                    label="Profile"
                    onClick={() => {
                        setProfileMenuOpen(false);
                        handleNavigation('/profile');
                    }}
                />
                <MenuItem
                    label="Logout"
                    onClick={() => {
                        setProfileMenuOpen(false);
                        dispatch({ type: AuthActionType.LOGOUT });
                        handleNavigation('/');
                    }}
                />
            </Menu>
        </>
    );
};

export default AccountActions;
