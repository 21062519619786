import React, { MouseEvent } from 'react';
import './menu.scss';
import ReactDOM from 'react-dom';
import { useWindowDimensions } from '../../../utils';

type Props = {
    elementRef: React.RefObject<HTMLElement>;
    open: boolean;
    close: () => void;
    width: number;
    children: React.ReactChild | React.ReactChild[];
    position?: 'bottom'| 'top' | 'left' | 'right';
    align?: 'left' | 'right';
};

const Menu: React.FC<Props> = ({ open, elementRef, width, children, align = 'left', close, position="bottom" }) => {
    const menuRef = React.useRef<HTMLDivElement>(null);
    const [top, setTop] = React.useState(0);
    const [left, setLeft] = React.useState(0);

    const windowWidth = useWindowDimensions().width;

    React.useEffect(() => {
        if (open) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const clickListner = (e: any) => {
                if (!elementRef.current?.contains(e.target) && e.target !== elementRef.current){
                    close();
                    document.removeEventListener('click', clickListner);
                }
            };
            document.addEventListener('click', clickListner);
            setPositionProperty();
        }
    }, [open]);

    React.useEffect(() => {
        setPositionProperty();
    }, [windowWidth]);

    const setPositionProperty = () => {
        if(menuRef.current) {            
        const elementHeight = elementRef.current ? elementRef.current.offsetHeight : 0;
        const elementWidth = elementRef.current ? elementRef.current.offsetWidth : 0;
        const elementLeft = elementRef.current ? elementRef.current.offsetLeft : 0;
        const elementTop = elementRef.current ? elementRef.current.offsetTop : 0;
        let tempTop = 0;
        let tempLeft = 0;
            switch(position) {
                case "bottom":
                   menuRef.current.style.paddingTop = "14px";                
                    tempTop =  elementHeight + elementTop - 4;
                    tempLeft = align=='left'? elementLeft: (elementLeft + elementWidth);
                break;
                case "top":
                    if(menuRef.current) menuRef.current.style.paddingBottom = "14px";
                    const menuHeight = menuRef.current ?menuRef.current.offsetHeight: 0;
                    tempTop = elementTop - menuHeight + 4;
                    tempLeft = align=='left'? elementLeft: (elementLeft + elementWidth);
                    break;
                case "left":
                    if(menuRef.current) menuRef.current.style.paddingRight = "14px";
                    tempTop = elementTop;
                    tempLeft = elementLeft - elementWidth + 4;
                    break;
                case "right":
                    if(menuRef.current) menuRef.current.style.paddingLeft = "14px";
                    tempTop = elementTop;
                    tempLeft = elementLeft + elementWidth - 4;
                    break;
                default:
                    break;
            }  
            if(tempLeft< 0) tempLeft=4;
            if((tempLeft + menuRef.current.offsetWidth)>window.innerWidth) tempLeft = (window.innerWidth - menuRef.current.offsetWidth-4);
            if(tempTop< 0) tempTop=4;
            if((tempTop + menuRef.current.offsetHeight)>window.innerHeight) tempTop = (window.innerHeight - menuRef.current.offsetHeight-4);
            setTop(tempTop);
            setLeft(tempLeft);
        }
        
    };

    return (
        <>
            {open &&
                elementRef &&
                ReactDOM.createPortal(
                    <div
                        className="menu-container"
                        style={{ top: top, left: left, width: width }}
                        ref={menuRef}
                        onMouseOver={(e: MouseEvent) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        {children}
                    </div>,
                    document.body
                )}
        </>
    );
};

export const MenuItem: React.FC<{ onClick: () => void; label: string, cypressId?: string  }> = ({ label, onClick, cypressId }) => {
    return (
        <div
            className="menu-item"
            tabIndex={0}
            onClick={onClick}
            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && onClick()}
            data-cy = {cypressId}
        >
            <p>{label}</p>
        </div>
    );
};

export default Menu;
