import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavPosition } from '../../config/models';
import { AuthContext } from '../../containers/Authentication/state';
import { UserRole } from '../../models/User';
import { getNavItemsByRole } from '../../utils/routeUtils';
type Props = {
    activeItem: string;
};

const HorizontalNav: React.FC<Props> = ({ activeItem }) => {
    const { user } = React.useContext(AuthContext).state;
    const [navItems, setNavItems] = React.useState<NavItem[]>([]);

    React.useEffect(() => {
        const topNavItems = getNavItemsByRole(user ? user.role : UserRole.Visitor, NavPosition.TOP);
        setNavItems(topNavItems);
    }, [user]);

    return (
        <nav role="navigation">
            {navItems.map((item, index) => (
                <Link
                    to={item.route}
                    key={index}
                    className={`nav-item ${item.label === activeItem ? 'active' : ''}`}
                    role="navigation"
                >
                    <span>{item.label}</span>
                </Link>
            ))}
        </nav>
    );
};
export default HorizontalNav;
