import Author from "./Author";

export default interface Content {
    id?: string;
    type: ContentType;
    title: string;
    subtitle?: string;    
    summary?:string;
    data: string;
    isFeatured?: boolean;
    featuredImage?: string;
    status: ContentStatus;
    order?: number;
    authorId?:string;
    author?: Author
    category?:string;
    createdAt?: string;
    createdById?: string;
    updatedAt?: string;
    updatedById?: string;
}
export type ContentConfig = {
    hasOrder?: boolean;
    hasSummary?: boolean;
    hasSubtitle?:boolean;
    hasFeaturedImage?:boolean;
    canFeature?:boolean;
    hasCategory?:boolean;
    hasAuthor?:boolean;
}

export enum ContentType {
    Faq = 0,    
    PrivacyPolicy = 1,
    Blog = 2,
    TermsAndConditions = 3,
    Disclaimer = 4
}

export enum ContentStatus {
    Draft = 0,
    Published = 1,
    Archived = 2,
}

export const getStatusOptions = (): Array<{ label: string; value: string }> => {
    const contentstatuses = Object.keys(ContentStatus).length / 2;
    const temp: Array<{ label: string; value: string }> = [];
    for (let i = 0; i < contentstatuses; i++) {
        temp.push({
            label: ContentStatus[i],
            value: i.toString(),
        });
    }
    return temp;
};
