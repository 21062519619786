import Author from '../models/Author';
import Content, { ContentType } from '../models/Content';
import instance from './instance';

function getContents(type: ContentType, category?: string, page?:number, size?:number): Promise<{ items: Content[]; total: number }> {
    const url = `contents?type=${type}${category && category.length > 0 ? `&category=${category}`: ''}${page ? `&page=${page}`: ''}${size ? `&size=${size}`: ''}`
    return instance.get(url).then((response) => {
        return {
            items: response.data.items ? response.data.items : [],
            total: response.data.total ? response.data.total : 0,
        };
    });
}

function createContent(content: Content): Promise<Content> {
    return instance.post(`content`, content).then((response) => {
        return response.data;
    });
}

function getContent(id: string): Promise<Content> {
    return instance.get(`content/${id}`).then((response) => {
        return response.data;
    });
}

function deleteContent(id: string): Promise<boolean> {
    return instance.delete(`content/${id}`).then((response) => {
        return response.data;
    });
}

function updateContent(content: Content): Promise<Content> {
    return instance.put(`content`, content).then((response) => {
        return response.data;
    });
}

function uploadMedia(blob: Blob, name: string): Promise<string> {
    const formData = new FormData();
    formData.append('file', blob, name);
    return instance.post(`content/upload`, formData).then((response) => {
        return response.data;
    });
}

function getAuthors():Promise<Author[]> {
    return instance.get(`content/authors`).then((response)=>{
        return response.data;
    })
}

function getCategories(type: ContentType):Promise<{label:string, value: string}[]> {
    
    return instance.get(`content/categories?type=${type}`).then((response)=>{
        return response.data;
    })
}

function updateAuthor(author:Author):Promise<boolean> {
    const request = author.id ? instance.put(`content/author`, author): instance.post(`/content/author`, author);
    return request.then((response)=>{
        return response.data;
    })
}

function getRelatedTrending(type: ContentType, category?: string, size= 4):Promise<Content[]> {
    const reqUrl = category ? `/contents/${type}/trending?category=${category}&size=${size}`: `/contents/${type}/trending?size=${size}`
    return instance.get(reqUrl).then((response)=>{
        return response.data;
    })
}

export { getContents, createContent, updateContent, getContent, deleteContent, uploadMedia, getAuthors, updateAuthor, getCategories, getRelatedTrending };
