import React from 'react';
import { ReportTemplatePage } from '../../models/Survey';
import { SurveyService } from '../../api';
import Typography from '../../components/Core/Typography';
import Upload from '../../components/Upload';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as ResetIcon } from '../../assets/icons/refresh-ccw.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x copy.svg';
import { ReactComponent as ViewIcon } from '../../assets/icons/external-link.svg';

import ErrorInfo from '../../components/Core/ErrorInfo';
import { Link } from 'react-router-dom';
export default function SurveyReportPages() {
    const [data, setData] = React.useState<ReportTemplatePage>({});
    const [isEdit, setIsEdit] = React.useState<0 | 1 | -1>(-1);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    const getData = () => {
        setRefresh(true);
        SurveyService.getReportPages().then((response) => setData(response)).finally(()=>setRefresh(false))
    };
    React.useEffect(() => {
        getData();
    }, []);

    const handleUpload = (file: File | File[]) => {
        const payload = file as File;
        setError('');
        setLoading(true);
        SurveyService.uploadReportPages(isEdit === 0 ? { coverTemplate: payload } : { endTemplate: payload })
            .then(() => {
                getData();
                setIsEdit(-1);
            })
            .catch(() => setError('Error uploading page'))
            .finally(() => setLoading(false));
    };

    const handleRevert = (type: 0 | 1) => {
        SurveyService.revertTemplatePage(type).then(() => {
            getData();
        });
    };

    return (
        <div style={{ marginTop: '48px' }}>
            <Typography variant="subtitle" weight={600} textAlign="center">
                REPORT PAGES
            </Typography>
            <div className="thumbnails">
                <div className="template-page">
                    <div className="report-page-action">
                        <IconButton Icon={ResetIcon} onClick={() => handleRevert(0)} tooltip="Revert page" />
                        <Link to={{pathname: data.newCoverPageUrl ?? '#'}} target="_blank" style={{ display: 'flex' }} >
                            <IconButton Icon={ViewIcon} tooltip="Open Page" />
                        </Link>
                        <IconButton Icon={EditIcon} onClick={() => setIsEdit(0)} tooltip="Edit pages" />
                    </div>
                    {!refresh && <object data={data.newCoverPageUrl} type="application/pdf" className="pdf-thumbnail">
                        <iframe src={data.newCoverPageUrl}></iframe>
                    </object>}
                    <Typography variant="caption" weight={600} textAlign="center">
                        COVER PAGE
                    </Typography>
                </div>
                <div className="template-page">
                    <div className="report-page-action">
                        <IconButton Icon={ResetIcon} onClick={() => handleRevert(1)} tooltip="Revert page" />
                        <Link to={{pathname: data.newBackPageUrl ?? '#'}} target="_blank" style={{ display: 'flex' }}>
                            <IconButton Icon={ViewIcon} tooltip="Open Page" />
                        </Link>
                        <IconButton Icon={EditIcon} onClick={() => setIsEdit(1)} tooltip="Edit pages" />
                    </div>
                      {!refresh && <object data={data.newBackPageUrl} type="application/pdf" className="pdf-thumbnail">
                        <iframe src={data.newBackPageUrl} width="100%" height="100%"></iframe>
                    </object>}
                    <Typography variant="caption" weight={600} textAlign="center">
                        BACK PAGE
                    </Typography>
                </div>
            </div>
            {isEdit !== -1 && (
                <div className="upload-pages">
                    <div className="upload-close-button">
                        <IconButton Icon={CloseIcon} onClick={() => setIsEdit(-1)} tooltip="Cancel" />
                    </div>
                    <br />
                    <br />
                    <Typography variant="body" weight={600}>
                        Upload new {isEdit === 0 ? 'Cover Page' : 'Back Page'}
                    </Typography>
                    <ErrorInfo error={error} onClose={() => setError('')} />
                    <Upload maxSize={512} handleUpload={handleUpload} fileTypes={['pdf']} loading={loading} />
                </div>
            )}
        </div>
    );
}
