import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavPosition, Route } from '../../config/models';
import { AuthContext } from '../../containers/Authentication/state';
import { UserRole } from '../../models/User';
import { useWindowDimensions } from '../../utils';
import { getNavItemsByRole } from '../../utils/routeUtils';
import Typography from '../Core/Typography';

type Props = { activeRoute: Route; isMinimized: boolean; closeNav: () => void };
const VerticalNav: React.FC<Props> = ({ isMinimized, activeRoute, closeNav }) => {
    const { user } = React.useContext(AuthContext).state;
    const [mainItems, setMaintems] = React.useState<NavItem[]>([]);
    const [helperItems, setHelpertems] = React.useState<NavItem[]>([]);
    const {width}= useWindowDimensions();

    React.useEffect(()=> {

        const vertivalNavItems = getNavItemsByRole(user ? user.role : UserRole.Visitor, NavPosition.VERTICAL);
        const footerNavItems = getNavItemsByRole(user ? user.role : UserRole.Visitor, NavPosition.FOOTER);
        const topNavItems = getNavItemsByRole(user ? user.role : UserRole.Visitor, NavPosition.TOP);
        const mainItemsTemp = width < 768 ? [...vertivalNavItems, ...topNavItems]: vertivalNavItems;        
        setMaintems(mainItemsTemp);
        setHelpertems(footerNavItems);
    }, [user])

    return (
        <nav className="navbar">
            <div className="nav-list">
                {mainItems.map((element, index) => {
                    const { Icon, label, route } = element;
                    return (
                        <Link
                            className={`nav-item${activeRoute.label === element.label ? ' active' : ''}`}
                            key={index}
                            to={route}
                            id = {element.label}
                            onClick={closeNav}
                        >
                            {Icon && (
                                <div title={element.label}>
                                    <Icon />
                                </div>
                            )}
                            {!isMinimized && <Typography variant="body">{label}</Typography>}
                        </Link>
                    );
                })}
            </div>
            {width < 768 && <div className="helper-section" role="navigation">
                <ul>
                    {helperItems.map((item, index) => {
                        const { label, route } = item;
                        return (
                            <li key={index} aria-label="navigation item">
                                <Link to={route} onClick={close}>
                                    {label}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>}
        </nav>
    );
};

export default VerticalNav;
