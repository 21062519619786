import { Steps } from 'intro.js-react';
import React from 'react';
import { AuthContext } from '../../containers/Authentication/state';
import 'intro.js/introjs.css';
import {useHistory, useLocation } from 'react-router-dom';
import { UserRole } from '../../models/User';
import { AuthService } from '../../api';
import { NotificationActionType, NotificationContext } from '../Core/Notification/state';
import {getSteps}  from './config.js';

type Props = {
    children: React.ReactNode;
};

const IntroTour: React.FC<Props> = ({children}) => {
    const {user} = React.useContext(AuthContext).state;
    const history = useHistory();
    const location = useLocation();
    const isAuthorized = (user?.role === UserRole.User && !user.hasTakenTour);
    const [showSteps,setShowSteps] = React.useState(false);
    const [enabled,setEnabled] = React.useState(false);
    const[initial, setInitial] = React.useState(0);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

     React.useEffect(()=>{
        if(!showSteps){
            if(location.pathname === '/surveys'){
                setInitial(0);
                setShowSteps(true);
                setTimeout(()=>setEnabled(true),1000);
                
            }
            else{
                setShowSteps(false);
            }
        }
     }, [location.pathname])

     const steps = getSteps();

     const handleExit = () => {
        if(enabled){
            setEnabled(false);
            AuthService.completeTour().catch((error)=> {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text:
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString(),
                        status: 'error',
                        autoClose: true,
                    },
                });
            }
            );
        }
     }
     const handleComplete = () => {
        AuthService.completeTour()
        .then(()=>{
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: `Tour Completed Successfully.`,
                    status: 'info',
                    autoClose: true,
                },
            });
        }).finally(()=>{
            history.push('/surveys');
        })
        .catch((error)=> {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text:
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString(),
                    status: 'error',
                    autoClose: true,
                },
            });
        }
        );
    }


     const handleChange = ( newStepIndex: number) =>{
        if( newStepIndex == 5){
            setEnabled(false);
            history.push('/surveys/add-new');
            setInitial(6);
            setTimeout(()=>{ setEnabled(true)},1000);  
        }
        if( newStepIndex == 11){
            setEnabled(false);
            history.push('/employees');
            setInitial(12);
            setTimeout(()=>setEnabled(true),1000);  
        }
        if( newStepIndex == 14){
            setEnabled(false);
            history.push('/employees/add-employee');
            setInitial(15);
            setTimeout(()=>setEnabled(true),1000);  
        }
        if( newStepIndex == 19){
            setEnabled(false);
            history.push('/blogs');
            setInitial(20);
            setTimeout(()=>setEnabled(true),1000);  
        }
        if( newStepIndex == 22){
            setEnabled(false);
            history.push('/faqs');
            setInitial(23);
            setTimeout(()=>setEnabled(true),1000);  
        }
     }

    return (
        <>
        {(isAuthorized && showSteps) &&
                 <Steps
                     enabled={enabled}
                     steps={steps}
                     initialStep={initial}
                     onExit={handleExit}
                     onAfterChange = {handleChange}
                     onComplete = {handleComplete}
                     options = {{ hideNext: false, doneLabel: 'End Tour', exitOnOverlayClick: false, showProgress : true, disableInteraction: true, showBullets: false, scrollToElement: true, autoPosition: true}}
                 />}
        {children}
        </>
    )
}

export default IntroTour;