import React from 'react';
import { AuthActionType, AuthContext } from '../../containers/Authentication/state';
import { UserRole } from '../../models/User';
import Typography from '../Core/Typography';
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as LeftIcon } from '../../assets/icons/arrow-left.svg';

import './editablepage.scss';
import IconButton from '../Core/IconButton';
import { useHistory, useParams } from 'react-router-dom';
import Toggle from '../Core/Toggle';

const EditablePage: React.FC<{ children: React.ReactChild }> = ({ children }) => {
    const authState = React.useContext(AuthContext).state;
    const dispatch = React.useContext(AuthContext).dispatch;
    const isAuthorized = authState.user?.role === UserRole.Admin || authState.user?.role === UserRole.Moderator;
    const [isNewContentPage, setIsNewContentPage] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    React.useEffect(() => {
        if (id && id === 'add-new') {
            setIsNewContentPage(true);
        }
    }, [id]);
    const handleChange = () => {
        dispatch({
            type: AuthActionType.SET_EDIT,
        });
    };

    const toggleChange = () => {
        if (isNewContentPage) {
            history.goBack();
        } else {
            let temp = history.location.pathname;
            temp = id ? temp.replace(id, 'add-new') : temp + '/add-new';
            history.push(temp);
        }
    };

    return (
        <>
            {isAuthorized && (
                <div className="editor-toggle">
                    <Typography variant="caption" color="white">
                        Editor mode
                    </Typography>
                    <Toggle name="editorView" value={authState.isEditorView} onChange={() => handleChange()} />
                </div>
            )}
            {isAuthorized && authState.isEditorView && (
                <>
                    <div className="add-content-toggle">
                        <IconButton
                            Icon={isNewContentPage ? LeftIcon : AddIcon}
                            color="white"
                            tooltip={isNewContentPage ? 'Go Back' : 'Add Content'}
                            onClick={toggleChange}
                        />
                    </div>
                </>
            )}
            {children}
        </>
    );
};

export default EditablePage;
