import { LoginUser, RegisterReq } from '../models/User';
import instance from './instance';

function loginWithToken(): Promise<LoginUser> {
    return instance.get('auth/token-login').then((response) => {
        return response.data;
    });
}
function register(user: RegisterReq): Promise<boolean> {
    return instance.post('auth/register', user).then((response) => {
        return response.data;
    });
}

function login(emailAddress: string, password: string): Promise<LoginUser> {
    return instance
        .post('auth/login', {
            emailAddress: emailAddress,
            password: password,
        })
        .then((response) => {
            return response.data;
        });
}

function resetPassword(token: string, email: string, password: string): Promise<string> {
    return instance
        .post(`auth/reset-forgotten-password`, {
            emailAddress: decodeURIComponent(email),
            token: token,
            password: password,
        })
        .then((response) => {
            return response.data;
        });
}

function forgotPassword(email: string): Promise<string> {
    return instance.get(`auth/forgot-password?email=${encodeURIComponent(email)}`).then((response) => {
        return response.data;
    });
}

function resetUserPassword(password: string): Promise<string> {
    return instance
        .post(`auth/reset-password`, {
            password: password,
        })
        .then((response) => {
            return response.data;
        });
}

function completeTour(): Promise<boolean> {
    return instance.put('tour').then((response) => {
        return response.data;
    });
}
export { loginWithToken, login, register, resetPassword, forgotPassword, resetUserPassword, completeTour };
