import React from 'react';
import InputField from '../../components/Core/InputField';
import Typography from '../../components/Core/Typography';

type Props = {
    order?: number;
    handleChange: (name: string, value: string | null) => void;
};
const OrderSelector: React.FC<Props> = ({ order, handleChange }) => {

    return (
        <div className="order-selector">
            <Typography variant="body" color="primary">
                Order
            </Typography>
            <InputField type="number" value={(order === 32767 || order === null) ? '': order } name="order" onChange={handleChange} placeholder="auto"/>
            <div className="clear-order">
                <p
                    onClick={() => {
                        handleChange('order', null);
                    }}
                >
                    Clear
                </p>
            </div>
        </div>
    );
};

export default OrderSelector;
