import React from 'react';
import './about.scss';
import Typography from '../../components/Core/Typography';
import AboutPattern from '../../assets/images/about-patterns.png'

const About: React.FC = () => {
    return (
        <section>
            <div className="page-intro">
                <h1 className="page-title" color="white">
                    About Us
                </h1>
                <br />
                <Typography variant="title" color="white" weight={300}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum dignissim elit, ac maximus
                    nunc porttitor at. Curabitur interdum turpis leo.
                </Typography>
            </div>
            <div className="page-details">
                <div className="detail-grid">
                    <div className="grid-text">
                        <Typography variant="heading" weight={300}>
                            Lorem ipsum dolor sit amet
                        </Typography>

                        <Typography variant="subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </Typography>
                        <br />
                        <Typography variant="subtitle" weight={600} color="primary">
                            consectetur adipiscing elit, sed
                        </Typography>
                        <Typography variant="subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography>

                        <br />
                        <Typography variant="subtitle" weight={600} color="primary">
                            consectetur adipiscing elit, sed
                        </Typography>
                        <Typography variant="subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography>
                    </div>
                    <div className="grid-image">
                        <img src={AboutPattern} alt="patterns for display" />
                    </div>
                    <div className="grid-image">
                        <img src={AboutPattern} alt="patterns for display" />
                    </div>
                    <div className="grid-text">
                        <Typography variant="heading" weight={300}>
                            Lorem ipsum dolor sit amet
                        </Typography>

                        <Typography variant="subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </Typography>
                        <br />
                        <Typography variant="subtitle" weight={600} color="primary">
                            consectetur adipiscing elit, sed
                        </Typography>
                        <Typography variant="subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography>

                        <br />
                        <Typography variant="subtitle" weight={600} color="primary">
                            consectetur adipiscing elit, sed
                        </Typography>
                        <Typography variant="subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Typography>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
