import React from 'react';
import './tooltip.scss';
import ReactDOM from 'react-dom';
import { useWindowDimensions } from '../../../utils';

type Props =
 {
    children: React.ReactElement;
    text?: string;
    width?: number;
};
const Tooltip: React.FC<Props> = ({ children, text, width }) => {
    const [id, setId] = React.useState<string>();
    const tooltipRef = React.useRef<HTMLLabelElement>(null);
    const{width: windowWidth} = useWindowDimensions();

    React.useEffect(() => {
        if (text && text.length > 0) {
            const temp = generateRandomId(text);
            setId(temp);
        }
    }, [text]);

    React.useEffect(() => {
        if (id) addEventListners();
    }, [id, children,windowWidth]);

    const addEventListners = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any 
        const temp = document.querySelectorAll(`[aria-labelledby="${id}"]`);
        if (temp) {
            const element = temp[0]; 
            element.addEventListener('click', showTooltip);
        
            
            {windowWidth>760 && element.addEventListener('mouseover', showTooltip); element.addEventListener('mouseout', hideTooltip);}

            return () => {
                element.removeEventListener('click', showTooltip);
                
                {windowWidth>760 && element.removeEventListener('mouseover', showTooltip); element.removeEventListener('mouseout', hideTooltip);}
                
            };
        }
    };

    const generateRandomId = (value: string) => {
        const a = value.split(''),
            n = a.length;

        for (let i = n - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        const temp = a.join('').replace(/ /g, '');
        return temp;
    };
    const showTooltip = () => {
        if (tooltipRef.current && tooltipRef.current.style.visibility !== 'visible') {
            const temp = document.querySelectorAll(`[aria-labelledby="${id}"]`);
            if (temp) {
                const element = temp[0];
                const { width: elementWidth, left: elementLeft, top: elementTop } = element.getBoundingClientRect();
                if (width) tooltipRef.current.style.width = `${width}px`;
                let tempLeft = elementLeft + elementWidth / 2;
                let tempTop = elementTop - 25;
                if (tempLeft < 0) tempLeft = 4;
                if (tempLeft + tooltipRef.current.offsetWidth > window.innerWidth)
                    tempLeft = window.innerWidth - tooltipRef.current.offsetWidth - 4;
                if (tempTop < 0) tempTop = 4;
                if (tempTop + tooltipRef.current.offsetHeight > window.innerHeight)
                    tempTop = window.innerHeight - tooltipRef.current.offsetHeight - 4;
                tooltipRef.current.style.left = `${tempLeft}px`;
                tooltipRef.current.style.top = `${tempTop}px`;
                tooltipRef.current.style.visibility = 'visible';
                const clickListner = (e: any) => {
                    if ((!element.contains(e.target)) && (e.target.id != id)) {                      
                         hideTooltip();
                        document.removeEventListener('click', clickListner);
                    }
                };
                document.addEventListener('click', clickListner);                
            }
        }
    };
    const hideTooltip = () => {
        if (tooltipRef.current) tooltipRef.current.style.visibility = 'hidden';
    };

    const RenderChild = () => {
        return text && text.length > 0
            ? React.cloneElement(children, {
                  'aria-labelledby': id,
              })
            : children;
    };

    return (
        <>
            <RenderChild />
            {text && text.length > 0 &&  ReactDOM.createPortal(
                <label id={id} className="tooltip-container" ref={tooltipRef} onMouseOver={(evt)=>{evt.stopPropagation(); evt.preventDefault()}}>
                    <span>{text}</span>
                </label>,
                    document.body as HTMLElement
            )}
        </>
    );
};

export default Tooltip;
