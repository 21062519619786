import React from 'react';
import { SurveyService } from '../../api';
import { DataLoadingContext } from '../../components/Core/DataLoading';
import { UserSurveyGrid } from '../../models/Survey';
import './survey.scss';
import { Grid, GridColumn, GridSortChangeEvent, GridCellProps, GridPageChangeEvent } from '@progress/kendo-react-grid';
import {  SortDescriptor } from '@progress/kendo-data-query';
import { AuthContext } from '../Authentication/state';
import { UserRole } from '../../models/User';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as UpIcon } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/chevron-down.svg';
import Typography from '../../components/Core/Typography';
import { formatDate } from '../../utils';
import SurveyCallouts from './SurveyCallouts';
import SurveyActions from './SurveyActions';
// import { useHistory } from 'react-router-dom';

interface PageState {
    skip: number;
    take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const Surveys: React.FC = () => {
    const [surveys, setSurveys] = React.useState<UserSurveyGrid[]>([]);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const[count, setCount] = React.useState(0);
    const [sort, setSort] = React.useState<SortDescriptor[]>([]);
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const { user } = React.useContext(AuthContext).state;
    const isAuthorized = user?.role === UserRole.Admin || user?.role === UserRole.Moderator;
    const [isListOpen, setIsListOpen] = React.useState(isAuthorized);

    
    // const history = useHistory();

    const pageChange = (event: GridPageChangeEvent) => {
        toggleLoading(true);
        const newPage = event.page;
        SurveyService.getSurveys((newPage.skip/newPage.take)+1)
            .then((response) => {
                setSurveys(response.items);
                setCount(response.total);
                setPage(newPage);
            })
            .finally(() => toggleLoading(false));    
    };

    // const rowClicked = (event: GridRowClickEvent) => {
    //     const survey = event.dataItem as UserSurveyGrid;
    //     history.push(`/surveys/${survey.surveyId}`);
    // }

    React.useEffect(() => {
        toggleLoading(true);
        SurveyService.getSurveys()
            .then((response) => {
                setSurveys(response.items);
                setCount(response.total);
            })
            .finally(() => toggleLoading(false));
    }, []);

    const DateCell=(props: GridCellProps)=> {
        return <td>{formatDate(props.dataItem.updatedAt)}</td>
    }
    const ScoreCell=(props: GridCellProps)=> {
        return(
            <>
                {
                    props.dataItem.currentStep === props.dataItem.totalSteps
                    ?
                    <td>
                        {
                            props.dataItem.grade == 'A+' &&
                            <Typography variant="heading" color='success'>
                            {props.dataItem.grade}
                            </Typography>
                        }
                        {
                             props.dataItem.grade == 'F' &&
                            <Typography variant="heading" color='error'>
                            { props.dataItem.grade}
                            </Typography>
                        }
                        {
                            !( props.dataItem.grade == 'A+' ||  props.dataItem.grade =='F') &&
                            <Typography variant="heading" color='primary'>
                            { props.dataItem.grade}
                            </Typography>
                        }

                    </td>
                    :
                    <td>
                        Incomplete
                    </td>
                }
            </> 
            )
    }

    const NameCell=(props: GridCellProps) => {
        return(
            <td>
                {props.dataItem.name}
            </td>
        )
    }

    const EmailCell=(props: GridCellProps) => {
        return(
            <td>
                {props.dataItem.emailAddress}
            </td>
        )
    }

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                
        {user && <SurveyActions user = {user} survey={props.dataItem as UserSurveyGrid} /> }               
            </td>
        );
    };

    return (
        <div className="surveys">
            <SurveyCallouts isAuthorized={isAuthorized} />
            <div className="paper">
                <div className="survey-history-title">
                    <Typography variant="title" weight={500} color="secondary">
                        SURVEY HISTORY
                    </Typography>
                    <IconButton Icon={isListOpen ? UpIcon : DownIcon} onClick={() => setIsListOpen(!isListOpen)} />
                </div>
                {surveys.length > 0 && !isAuthorized && (
                    <div>
                        <Typography variant="caption">Last Evaluation: </Typography>
                        <Typography variant="subtitle">{formatDate(surveys[0].updatedAt)}</Typography>
                        <Typography variant="caption">Score: </Typography>
                        {surveys[0].finalScore === -1 ?
                            <Typography variant="subtitle" color="error" weight={600}>
                                Failed
                            </Typography>
                            :
                            <Typography variant="subtitle" color="success" weight={600}>
                                {surveys[0].finalScore}
                            </Typography>
                        }
                        <br />
                    </div>
                )}
                <div></div>
                {isListOpen && (
                    <Grid
                        style={{ height: '100%' }}
                        data={surveys}
                        sort={sort}
                        sortable={true}
                        onSortChange={(e: GridSortChangeEvent) => {
                            setSort(e.sort);
                        }}
                        total={count}
                        take={page.take}
                        skip={page.skip}
                        pageable={true}
                        onPageChange={pageChange}
                        
                    >
                        <GridColumn field="updatedAt" title="Date" cell={DateCell}/>
                        <GridColumn field="name" title="Name" cell={NameCell}/>
                        <GridColumn field="emailAddress" title="Email" cell={EmailCell}/>
                        <GridColumn field="finalScore" title="Score" cell={ScoreCell}/>
                        <GridColumn cell={GridActions} filterable={false} />
                    </Grid>
                )}
            </div>
        </div>
    );
};

export default Surveys;
