import React from 'react';
import { ContentService } from '../../api';
import EditablePage from '../../components/EditablePage';
import Content, { ContentType } from '../../models/Content';
import DefaultListItemTemplate from '../ContentManager/DefaultListItemTemplate';

const Disclaimer:React.FC=()=> {
    const [contents, setContents] = React.useState<Content[]>([]);

    React.useEffect(() => {
        ContentService.getContents(ContentType.Disclaimer).then((response) => setContents(response.items));
    }, []);

    return (
        <EditablePage>
            <>               
                {contents.map((content, index) => (
                    <DefaultListItemTemplate content={content} key={index}/>
                ))}
            </>
        </EditablePage>
    );
}
export default Disclaimer