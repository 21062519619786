import React from "react";
import { Link } from "react-router-dom";
import { ConsensusService } from "../../api";
import {ReactComponent as ExternalLinkIcon} from '../../assets/icons/external-link.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { AlertDialogActionType, AlertDialogContext } from "../../components/Core/AlertDialog/state";
import IconButton from "../../components/Core/IconButton";
import Consensus from "../../models/Consensus";

type Props = {
    consensus: Consensus;
    fetchData: () => void;
}

const ConsensusActions: React.FC<Props> = ({ consensus, fetchData}) =>{
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const handleOpenDeleteStationDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Station',
                description: `Are you sure you want to delete ${consensus.firstName} ${consensus.lastName}?`,
                handleConfirm: () => handleDelete(consensus.id as string),
            },
        });

        const handleDelete = (id: string) => {
            ConsensusService.deleteConsensus(id).then(() => {
                fetchData();
            });
        };
    };

    return (
        <>
        <Link to={`employees/${consensus.id}`}>
            <IconButton Icon={ExternalLinkIcon} color="info" />
        </Link>
        <IconButton Icon={DeleteIcon} onClick={handleOpenDeleteStationDialog} color="error" tooltip="Delete Employee"/>
        </>
    )
}

export default ConsensusActions;