import { UserRole } from "../models/User";
import { NavPosition, Route } from "./models";
import ProfilePage from '../containers/Profile';
import LandingPage from '../containers/Landing';
// import DashboardPage from '../containers/Dashboard';
import Surveys from '../containers/Surveys';
import ConsensusMain from "../containers/Consensus";
import ConsensusPage from "../containers/Consensus/ConsensusPage";
import ForgotPasswordPage from '../containers/ForgotPassword';
import ResetPasswordPage from '../containers/ResetPassword';
import {ReactComponent as SurveyIcon } from '../assets/icons/survey.svg';
import {ReactComponent as UsersIcon } from '../assets/icons/users.svg';
import {ReactComponent as BlogIcon } from '../assets/icons/book-open.svg';
import {ReactComponent as FAQIcon } from '../assets/icons/help-circle.svg';
import {ReactComponent as IntroIcon } from '../assets/icons/info.svg';
import NotFound from "../containers/NotFound";
import RegisterPage from "../containers/Register";
import BlogsPage from "../containers/Blogs";
import UsersPage from "../containers/Users";
import UserDetail from "../containers/Users/UserDetail";
import TestIntro from "../components/IntroYour/TestIntro";
import Faqs from "../containers/Faqs";
import Disclaimer from "../containers/Disclaimer";
import TermsAndConditions from "../containers/TermsAndConditions";
import UpdatePassword from "../containers/UpdatePassword";
import SingleTemplateFaq from "../containers/Faqs/SingleTemplate";
import SingleTemplateTAC from "../containers/TermsAndConditions/SingleTemplate";
import SingleTemplateDisclaimer from "../containers/Disclaimer/SingleTemplate";
import SurveyDetail from "../containers/Surveys/SurveyDetail";
import SurveyTemplates from "../containers/SurveyTemplates";
import BlogSingleTemplate from "../containers/Blogs/BlogSingleTemplate";
import SurveyTemplateDetail from "../containers/SurveyTemplates/SurveyTemplateDetail";
import About from "../containers/About";
import Pricing from "../containers/HrNews";
import HrNews from "../containers/Pricing";

const routes: Array<Route> = [
    {
        label: 'Forgot Password',
        route: '/forgot-password',
        roles: [UserRole.Visitor],
        Component: ForgotPasswordPage,
        exact: true,
    },
    {
        label: 'About Us',
        route: '/about',
        roles: [UserRole.Visitor],
        Component: About,
        navPosition: NavPosition.TOP,
        showHorizontalNav: true,
        exact: true,
        class: 'about-page',
    },
    {
        label: 'Pricing',
        route: '/pricing',
        roles: [UserRole.Visitor],
        Component: Pricing,
        navPosition: NavPosition.TOP,
        showHorizontalNav: true,
        exact: true,
    },
    {
        label: 'HR News',
        route: '/news',
        roles: [UserRole.Visitor],
        Component: HrNews,
        showHorizontalNav: true,
        navPosition: NavPosition.TOP,
        exact: true,
    },
    {
        label: 'Update Password',
        route: '/update-password',
        roles: [UserRole.User, UserRole.Admin],
        Component: UpdatePassword,
        showVerticalNav: true,
        showHorizontalNav: true,
        exact: false,
    },
    {
        label: 'Reset Password',
        route: '/reset-password/:token/:emailAddress',
        roles: [UserRole.Visitor],
        Component: ResetPasswordPage,
        exact: false,
    },
    {
        label: 'Profile',
        route: '/profile',
        roles: [UserRole.User, UserRole.Admin],
        Component: ProfilePage,
        showVerticalNav: true,
        showHorizontalNav: true,
        exact: true,
    },
    // {
    //     label: 'Dashboard',
    //     route:  '/dashboard',
    //     roles:  [UserRole.Admin],
    //     Component: DashboardPage,
    //     navPosition: NavPosition.VERTICAL,
    //     showHorizontalNav: true,
    //     showVerticalNav: true,
    //     Icon: DashboardIcon,
    //     exact: true
    // },
    {
        label: 'Survey',
        route: '/surveys',
        roles: [UserRole.User, UserRole.Admin, UserRole.Moderator],
        Component: Surveys,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showVerticalNav: true,
        Icon: SurveyIcon,
        exact: true,
    },
    {
        label: 'Survey',
        route: '/surveys/:id',
        roles: [UserRole.User, UserRole.Admin, UserRole.Moderator],
        Component: SurveyDetail,
        showHorizontalNav: true,
        showVerticalNav: true,
        exact: true,
    },
    {
        label: 'Surveys',
        route: '/surveys/admin/templates',
        roles: [UserRole.Admin, UserRole.Moderator],
        Component: SurveyTemplates,
        showHorizontalNav: true,
        showVerticalNav: true,
        exact: true,
    },
    {
        label: 'Surveys',
        route: '/surveys/admin/templates/:id',
        roles: [UserRole.Admin, UserRole.Moderator],
        Component: SurveyTemplateDetail,
        showHorizontalNav: true,
        showVerticalNav: true,
        exact: true,
    },
    {
        label: 'Employees',
        route: '/employees',
        roles: [UserRole.User],
        Component: ConsensusMain,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showVerticalNav: true,
        Icon: UsersIcon,
        exact: true,
    },
    {
        label: 'ConsensusDetail',
        route: '/employees/:id',
        roles: [UserRole.User],
        Component: ConsensusPage,
        showHorizontalNav: true,
        showVerticalNav: true,
        exact: true,
    },
    {
        label: 'Users',
        route: '/users',
        roles: [UserRole.Admin],
        Component: UsersPage,
        showVerticalNav: true,
        showHorizontalNav: true,
        navPosition: NavPosition.VERTICAL,
        Icon: UsersIcon,
        exact: true,
    },
    {
        label: 'Users',
        route: '/users/:id',
        roles: [UserRole.Admin],
        Component: UserDetail,
        exact: true,
    },
    {
        label: 'Not Found',
        route: '/404',
        roles: [UserRole.User, UserRole.Admin, UserRole.Visitor],
        Component: NotFound,
        exact: true,
    },
    {
        label: 'Register',
        route: '/register',
        roles: [UserRole.Visitor],
        Component: RegisterPage,
        showHorizontalNav: true,
        class: 'register-page',
        exact: true,
    },
    {
        label: 'Blogs',
        route: '/blogs',
        roles: [UserRole.User, UserRole.Admin],
        Component: BlogsPage,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showFooter: true,
        showVerticalNav: true,
        Icon: BlogIcon,
        exact: true,
    },
    {
        label: 'Tour',
        route: '/tour',
        roles: [UserRole.User],
        Component: TestIntro,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showFooter: true,
        showVerticalNav: true,
        Icon: IntroIcon,
        exact: true,
    },
    {
        label: 'Disclaimer',
        route: '/disclaimer',
        roles: [UserRole.User, UserRole.Admin],
        Component: Disclaimer,
        navPosition: NavPosition.FOOTER,
        showVerticalNav: true,
        showHorizontalNav: true,
        exact: true,
    },
    {
        label: 'Terms And Conditions',
        route: '/terms-and-conditions',
        roles: [UserRole.User, UserRole.Admin],
        Component: TermsAndConditions,
        navPosition: NavPosition.FOOTER,
        showVerticalNav: true,
        showHorizontalNav: true,
        exact: true,
    },
    {
        label: 'Blogs',
        route: '/blogs/:id',
        roles: [UserRole.User, UserRole.Admin],
        Component: BlogSingleTemplate,
        showHorizontalNav: true,
        showFooter: true,
        showVerticalNav: true,
        exact: true,
    },
    {
        label: 'Faqs',
        route: '/faqs',
        roles: [UserRole.User, UserRole.Admin],
        Component: Faqs,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showFooter: true,
        showVerticalNav: true,
        Icon: FAQIcon,
        exact: true,
    },
    {
        label: 'Faqs',
        route: '/faqs/:id',
        roles: [UserRole.User, UserRole.Admin],
        Component: SingleTemplateFaq,
        showHorizontalNav: true,
        showFooter: true,
        showVerticalNav: true,
        exact: true,
    },
    {
        label: 'Disclaimer',
        route: '/Disclaimer/:id',
        roles: [UserRole.User, UserRole.Admin],
        Component: SingleTemplateDisclaimer,
        showHorizontalNav: true,
        showFooter: true,
        showVerticalNav: true,
        exact: true,
    },
    {
        label: 'Terms And Conditions',
        route: '/terms-and-conditions/:id',
        roles: [UserRole.User, UserRole.Admin],
        Component: SingleTemplateTAC,
        showHorizontalNav: true,
        showFooter: true,
        showVerticalNav: true,
        exact: true,
    },

    //keep landing route at the end for active route filtering
    {
        label: 'Landing',
        route: '/',
        roles: [UserRole.Visitor],
        Component: LandingPage,
        showHorizontalNav: true,
        class: 'landing-page',
        exact: true,
    },
];

export default routes;