import React from 'react';
import { DataLoadingContext } from '../../components/Core/DataLoading';
import SurveyTemplate from '../../models/Survey';
import { SurveyService } from '../../api';
import Typography from '../../components/Core/Typography';
import { formatDate } from '../../utils';
import Tag from '../../components/Core/Tag';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import './surveytemplate.scss';
import { Link } from 'react-router-dom';
import SurveyCreate from './SurveyCreate';
import SurveyReportPages from './SurveyReportPages';

const SurveyTemplates: React.FC = () => {
    const [surveyTemplates, setSurveyTemplates] = React.useState<SurveyTemplate[]>([]);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [showCreateModal, setShowCreateModal] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        SurveyService.getAllTemplates()
            .then((response) => {
                setSurveyTemplates(response);
            })
            .finally(() => toggleLoading(false));
    };

    const handleActivation = (id: string) => {
        SurveyService.activateTemplate(id).then(() => getData());
    };
    return (
        <div className="survey-templates">
            <Typography variant="heading" color="secondary" textAlign="center">
                TEMPLATES
            </Typography>
            <div className="templates">
                <div className="template paper" onClick={() => setShowCreateModal(true)}>
                    <br/>
                    <Typography variant="subtitle" color="secondary" textAlign='center'>
                        CREATE NEW
                    </Typography>
                </div>
                {surveyTemplates.map((template) => (
                    <Link to={`/surveys/admin/templates/${template.id}`} key={template.id} className="template paper">
                        <Typography variant="body">{template.name}</Typography>
                        <Typography variant="caption">{formatDate(template.createdAt as string)}</Typography>
                        {template.isLatest && <Tag color="info" label="active" />}
                        {!template.isLatest && (
                            <div className="template-action">
                                <IconButton
                                    Icon={CheckIcon}
                                    onClick={() => handleActivation(template.id as string)}
                                    color="info"
                                    tooltip="activate"
                                />
                            </div>
                        )}
                    </Link>
                ))}
            </div>

            <SurveyReportPages />
            <SurveyCreate
                open={showCreateModal}
                close={() => {
                    setShowCreateModal(false);
                    getData();
                }}
            />
        </div>
    );
};

export default SurveyTemplates;
