import React from 'react';
import { useParams } from 'react-router-dom';
import { SurveyService } from '../../api';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import Button from '../../components/Core/Button';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import Typography from '../../components/Core/Typography';
import SurveyTemplate, { Question, SurveyCategory, initQuestion } from '../../models/Survey';
import BuilderQuestionCard from './BuilderQuestionCard';
import './surveytemplate.scss';
import TextArea from '../../components/Core/TextArea';
import InputField from '../../components/Core/InputField';

export const getOptions = (categories: SurveyCategory[])=>{
    const options: Option[] = categories.map((category) => ({
        label: category.category,
        value: category.category,
    }));

    return options;
}

type Option = {
    label: string;
    value: string;
};

const SurveyTemplateDetail: React.FC = () => {
    const [template, setTemplate] = React.useState<SurveyTemplate>();
    const [loading, setLoading] = React.useState(false);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [isValid, setIsValid] = React.useState(true);
    const [open, setOpen] = React.useState('');
    const [invalidId, setInvalidId] = React.useState('');
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const { id } = useParams<{ id: string }>();
    const[categories, setCategories] = React.useState<Option[]>([]);

    React.useEffect(() => {
        getData();
    }, [id]);

    React.useEffect(()=>{
        getCategories();
    },[])
    
    const getCategories = () => {
        SurveyService.getAllCategories().then((res)=> {
            setCategories(getOptions(res));
        });
    }

    const handleInvalid = () =>{
        if(template){
        let valid = true;
        if(template.name.length===0) {
            valid = false;
        }
        let invalidIdentifier = '';
        // Create a stack to keep track of the objects to process
        const stack = [...template.questions.map((q, index) => ({ question: q, identifier: `${index + 1}` }))];

        // Loop through the stack until it is empty or an invalid node is found
        while (stack.length > 0 && valid) {
            // Pop the next object from the stack
            const temp = stack.pop();
            if (temp) {
                valid = checkQuestionValidity(temp.question);
                if (!valid) {
                    invalidIdentifier = temp.identifier;
                }
                // Add the children of the current object to the stack
                const children = temp.question?.metaData.subQuestions ?? [];
                for (const [index, child] of children.entries()) {
                    stack.push({ question: child, identifier: `${temp.identifier}.${index + 1}` });
                }
            }
        }
        setIsValid(valid);
        setInvalidId(invalidIdentifier);
        }
    }

    const getData = () => {
        SurveyService.getTemplateById(id).then((response) => {
            setTemplate(response);
        });
    };

    const handleQuestionAdd = () => {
        const temp = { ...(template as SurveyTemplate) };
        const newQuestionIdentifier = (temp.questions.length + 1).toString();
        setTemplate({
            ...temp,
            questions: [...temp.questions, initQuestion],
            totalSteps: temp.totalSteps + 1,
        });
        setOpen(newQuestionIdentifier);
    };

    const handleApplyChanges = (updatedQuestion: Question, identifier?: string) => {
        if (checkQuestionValidity(updatedQuestion)) {   
            const selectedIdentifier = identifier ?? open; 
            getCategories();
            const tempArr = selectedIdentifier.split('.');
            const temp = { ...(template as SurveyTemplate) };
            let questions = temp.questions;
            tempArr.forEach((el, index) => {
                const isLast = index === tempArr.length - 1;
                const indexEl = parseInt(el) - 1;
                if (isLast) {
                    questions[indexEl] = updatedQuestion;
                    setTemplate({ ...(temp as SurveyTemplate) });
                } else {
                    questions = questions[indexEl].metaData.subQuestions
                        ? (questions[indexEl].metaData.subQuestions as Question[])
                        : [];
                }
            });
            handleInvalid();
            setOpen('');
        }
    };

    const handleReorderStyle = (identifiers: string[], newIndex: number) => {
        const temp = [...identifiers];
        temp[temp.length - 1] = (newIndex + 1).toString();
        const newIdentifier = temp.join('.');
        const el = document.getElementById(newIdentifier);
        if (el) {
            el.style.backgroundColor = '#F0EEE4';
            setTimeout(() => {
                el.style.backgroundColor = '#F9F7F8';
            }, 500);
        }
    };

    const handleReorder = (identifier: string, direction: 'up' | 'down') => {
        const tempArr = identifier.split('.');
        const temp = { ...(template as SurveyTemplate) };
        let questions = temp.questions;
        tempArr.forEach((el, index) => {
            const isLast = index === tempArr.length - 1;
            const indexEl = parseInt(el) - 1;
            if (isLast) {
                const newIndex = direction === 'up' ? indexEl + 1 : indexEl - 1;
                const tempQuestion = questions[indexEl];
                questions[indexEl] = questions[newIndex];
                questions[newIndex] = tempQuestion;
                setTemplate({ ...(temp as SurveyTemplate) });
                setTimeout(() => {
                    handleReorderStyle(tempArr, newIndex);
                }, 500);
            } else {
                questions = questions[indexEl].metaData.subQuestions
                    ? (questions[indexEl].metaData.subQuestions as Question[])
                    : [];
            }
        });
    };

    const handleRemove = () => {
        const tempArr = open.split('.');
        const temp = { ...(template as SurveyTemplate) };
        let questions = temp.questions;
        tempArr.forEach((el, index) => {
            const isLast = index === tempArr.length - 1;
            const indexEl = parseInt(el) - 1;
            if (isLast) {
                questions.splice(indexEl, 1);
                if (tempArr.length === 1) {
                    temp.totalSteps = temp.totalSteps - 1;
                }
                setTemplate(temp);
            } else {
                questions = questions[indexEl].metaData.subQuestions
                    ? (questions[indexEl].metaData.subQuestions as Question[])
                    : [];
            }
        });
        setOpen('');
    };

    const checkQuestionValidity = (updatedQuestion: Question): boolean => {
        let tempVal = true;

        if (updatedQuestion.hasSubQuestions) {
            tempVal =
                updatedQuestion.title.length > 0 && updatedQuestion.metaData.subQuestions
                    ? updatedQuestion.metaData.subQuestions.length > 0
                    : false;
        } else {
            const weightIsValid = updatedQuestion.weight
                ? /^\d+(\.\d+)?$/.test(updatedQuestion.weight.toString())
                : false;
            tempVal = updatedQuestion.title.length > 0 && updatedQuestion.category !== undefined && weightIsValid;
        }
        return tempVal;
    };

    const handleSaveSurveyTemplate = () => {
        if (template?.isLatest) {
            alertDialogDispatch({
                type: AlertDialogActionType.OPEN,
                payload: {
                    description: 'Are you sure you want to make changes to an active survey?',
                    handleConfirm: () => SaveOperation(template),
                    title: 'Update Template',
                },
            });
        } else {
            SaveOperation(template as SurveyTemplate);
        }
    };

    const SaveOperation = (UpdatedTemplate: SurveyTemplate) => {
        setLoading(true);
        SurveyService.updateSurveyTemplate(UpdatedTemplate)
            .then(() => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: `Survey template was successfully saved.`,
                        status: 'success',
                        autoClose: true,
                    },
                });
            })
            .catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (!template) return null;
    return (
        <div className="survey-builder">
            {template.isLatest && (
                <>
                    <Typography variant="body" color="error" textAlign="center">
                        You are updating an active survey
                    </Typography>
                    <br />
                </>
            )}
            <Typography variant="subtitle">Survey Title</Typography>
            <InputField
                onChange={(name, value) => setTemplate({ ...template, [name]: value })}
                value={template?.name ?? ''}
                name="name"
                error={!isValid && template.name.length === 0}
            />
            <Typography variant="subtitle">Survey Description</Typography>
            <TextArea
                onChange={(name, value) => setTemplate({ ...template, [name]: value })}
                value={template?.description ?? ''}
                name="description"
            />
            <Typography variant="subtitle">Survey Questions</Typography>
            {template.questions.length === 0 && (
                <div className="paper">
                    <Typography variant="subtitle" textAlign="center">
                        Please add a question to get started.
                    </Typography>
                </div>
            )}
            {template.questions.map((question, index) => (
                <BuilderQuestionCard
                    question={question}
                    handleApplyChanges={handleApplyChanges}
                    identifier={(index + 1).toString()}
                    handleRemove={handleRemove}
                    invalidId={invalidId}
                    open={open}
                    setOpen={(value) => setOpen(value)}
                    handleReorder={handleReorder}
                    totalSiblings={template.questions.length}
                    key={`${index}-${new Date()}`}
                    categoryOptions = {categories}
                />
            ))}
            <div className="action-container">
                <Button color="primary" onClick={handleQuestionAdd} className="add-question-button" disabled={!isValid}>
                    Add New Question
                </Button>
                <Button
                    color="success"
                    onClick={handleSaveSurveyTemplate}
                    className="add-question-button"
                    disabled={!isValid || template.questions.length === 0}
                    loading={loading}
                >
                    SAVE
                </Button>
            </div>
        </div>
    );
};

export default SurveyTemplateDetail;
