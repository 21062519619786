import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Core/Button';
import Typography from '../../components/Core/Typography';
import Content from '../../models/Content';
import { formatDate } from '../../utils';

const BlogListTemplate: React.FC<{ content: Content }> = ({ content }) => {
    return (
        <div className={`blog-card${content.isFeatured ? ' featured' : ''}`}>
            <div className="featured-image">
                <img
                    src={content.featuredImage as string}
                    alt={content.title + 'featured image'}
                />
            </div>
            <div className="card-content">
                <Typography variant="caption">
                    <span className="color-black">{content.category?.toUpperCase()}</span>
                    <strong> - {formatDate(content.createdAt as string)}</strong>
                </Typography>
                <Typography variant="subtitle" weight={600} color="secondary">
                    {content.title}
                </Typography>
                <div className='text-content'>
                {content.summary && (
                    <Typography variant="body">
                        {content.summary.slice(0, 160)}
                        {content.summary.length > 160 ? '...' : ''}
                    </Typography>
                )}
                </div>
                <Link to={`/blogs/${content.id}`}><Button color="primary">Read More</Button></Link>
            </div>
        </div>
    );
};

export default BlogListTemplate;
