import React from 'react';
import { ContentService } from '../../api';
import Author from '../../models/Author';
import CreatableSelect from 'react-select/creatable';
import { components, OptionProps } from 'react-select';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x.svg';
import Avatar from '../../components/Core/Avatar';
import Typography from '../../components/Core/Typography';
import './authorSelector.scss';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import InputField from '../../components/Core/InputField';
import { emailIsValid, urlIsValid } from '../../utils';
import TextArea from '../../components/Core/TextArea';
import Upload from '../../components/Upload';
import IconButton from '../../components/Core/IconButton';
import Button from '../../components/Core/Button';

type Props = {
    handleAuthorSelection: (authorId: string) => void;
    authorId?: string;
};

const AuthorSelector: React.FC<Props> = ({ handleAuthorSelection, authorId }) => {
    const [authors, setAuthors] = React.useState<Author[]>([]);
    const [contentAuthor, setContentAuthor] = React.useState<Author | undefined>();
    const [selected, setSelected] = React.useState<Author | null>(null);
    const [showError, setShowError] = React.useState(false);
    const [showUpload, setShowUpload] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        ContentService.getAuthors().then((response) => {
            setAuthors(response);
        });
    };

    React.useEffect(()=> {
        if(authors.length>0) {                    
            const temp = authors.find((el) => el.id === authorId);
            setContentAuthor(temp);
        }
    }, [authors, authorId])

    const handleChange = (newAuthor: Author) => {
        handleAuthorSelection(newAuthor.id as string);
    };

    const handleEdit = (name: string, value: string) => {
        setSelected({ ...(selected as Author), [name]: value });
    };

    const handleCreation = (inputValue: string) => {
        setSelected({
            name: inputValue,
            imageUrl: '',
            title: '',
        });
    };

    const handleUpload = (file: File | File[]) => {
        setLoading(true);
        ContentService.uploadMedia(file as Blob, `author-image-${new Date().toLocaleTimeString()}`)
            .then((response) => {
                setSelected({ ...(selected as Author), imageUrl: response });
                setShowUpload(false);
            })
            .finally(() => setLoading(false));
    };

    const handleUpdate = () => {
        const isValid =
            selected &&
            (selected.emailAddress ? emailIsValid(selected.emailAddress) : true) &&
            urlIsValid(selected.imageUrl) &&
            selected.name.length > 0 &&
            selected.title.length > 0;
        if (isValid) {
            setLoading(true);
            ContentService.updateAuthor(selected as Author)
                .then(() => {
                    setSelected(null);
                    setShowError(false);
                    setShowUpload(false);
                    getData();
                })
                .finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };

    const Option: React.FC<OptionProps<Author>> = (props: OptionProps<Author>) => {
        return (
            <div className="author-option">
                <components.Option {...props} className="tag-option-item">
                    <div className="author-details">
                        <Avatar size="small" source={props.data.imageUrl} name={props.data.name} />
                        <div>
                            <Typography variant="body" weight={600}>
                                {props.data.name? props.data.name : ((props.data as unknown) as {label:string, value: string}).label}
                            </Typography>
                            {props.data.title && <Typography variant="body">{props.data.title}</Typography>}
                            {props.data.professionalSummary && (
                                <Typography variant="caption">{props.data.professionalSummary}</Typography>
                            )}
                        </div>
                    </div>
                </components.Option>
                <div className="author-option-action">
                    <EditIcon
                        onClick={() => {
                            setSelected(props.data);
                        }}
                        className="edit-icon"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="author-selector">
            <Typography variant="body">Author</Typography>
            <CreatableSelect
                components={{ Option }}
                onChange={(el) => handleChange(el as Author)}
                isMulti={false}
                options={authors}
                value={contentAuthor}
                getOptionValue={(el) => el.id as string}
                getOptionLabel={(el) => el.name}
                onCreateOption={handleCreation}
            />
            {selected && (
                <Modal
                    isOpen={selected !== null}
                    close={() => setSelected(null)}
                    size="medium"
                    title={selected?.id ? 'Edit Author' : 'Create Author'}
                >
                    <div className={`image-container${showError && !urlIsValid(selected.imageUrl) ? ' error' : ''}`}>
                        <Avatar size="large" source={selected.imageUrl} name={selected.name} />
                        <div className="upload-button">
                            <IconButton
                                onClick={() => setShowUpload(true)}
                                color="white"
                                Icon={EditIcon}
                                tooltip="Update picture"
                            />
                        </div>
                    </div>
                    <br />
                    <>
                        {showUpload && (
                            <div className="profile-upload">
                                <Upload
                                    handleUpload={handleUpload}
                                    isAvatar={true}
                                    isMultiple={false}
                                    maxSize={500}
                                    loading={loading}
                                />
                                <div className="close-button">
                                    <IconButton onClick={() => setShowUpload(false)} Icon={CloseIcon} tooltip="Close" />
                                </div>
                            </div>
                        )}
                    </>
                    <div>
                        <Typography variant="body">
                            Name<span className="color-error">*</span>
                        </Typography>
                        <InputField
                            value={selected?.name}
                            name="name"
                            onChange={handleEdit}
                            error={showError && !(selected.name.length > 0)}
                            errorText="required"
                        />
                    </div>

                    <div className="form-grid-half">
                        <div>
                            <Typography variant="body">
                                Title<span className="color-error">*</span>
                            </Typography>
                            <InputField
                                value={selected?.title}
                                name="title"
                                onChange={handleEdit}
                                error={showError && !(selected.title.length > 0)}
                                errorText="required"
                            />
                        </div>
                        <div>
                            <Typography variant="body">Email Address</Typography>
                            <InputField
                                value={selected?.emailAddress}
                                name="emailAddress"
                                onChange={handleEdit}
                                error={
                                    showError && (selected.emailAddress ? !emailIsValid(selected.emailAddress) : false)
                                }
                                errorText="Please provide a valid email"
                            />
                        </div>
                    </div>
                    <Typography variant="body">Professional Summary</Typography>
                    <TextArea value={selected.professionalSummary} name="professionalSummary" onChange={handleEdit} />
                    <ModalActionBar>
                        <Button
                            onClick={() => {
                                setSelected(null);
                                setShowError(false);
                                setShowUpload(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => handleUpdate()}>{!selected.id ? 'Create' : 'update'}</Button>
                    </ModalActionBar>
                </Modal>
            )}
        </div>
    );
};

export default AuthorSelector;
