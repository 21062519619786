
import React from "react";
import { useHistory } from "react-router-dom";
import Consensus from "../../models/Consensus";
import { amountIsValid, endDateIsValid, getStates } from "../../utils";
import Typography from '../../components/Core/Typography';
import InputField from "../../components/Core/InputField";
import InputSelect from "../../components/Core/InputSelect";
import Button from "../../components/Core/Button";
import TextArea from "../../components/Core/TextArea";
import './consensus.scss';
import { ConsensusService } from "../../api";
import { NotificationActionType, NotificationContext } from "../../components/Core/Notification/state";


type Props ={
    consensus: Consensus;
    toggleView: ()=> void;
}


const ConsensusForm: React.FC<Props> = ({consensus, toggleView}) =>{
    const[_consensus, setConsensus] = React.useState(consensus);
    const[reports,setReports] = React.useState<Array<string>>(_consensus.directReports);
    const[newReport, setNewReport] = React.useState('');
    const[showError, setShowError] = React.useState(false);
    const[isLoading, setIsLoading] = React.useState(false);
    const history = useHistory();
    const states = getStates();
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    const genderList = [{label:"Male", value:"Male"},{label:"Female", value:"Female"},{label:"Others", value:"Others"}];
    const ethnicityList = [
        {label: "White", value: "White"},
        {label: "Black or African American", value: "Black or African American"},
        {label: "American Indian or Alaska Native", value: "American Indian or Alaska Native"},
        {label: "Asian", value: "Asian"},
        {label: "Native Hawaiian or Other Pacific Islander", value: "Native Hawaiian or Other Pacific Islander"},
        {label: "Others", value: "Others"}
    ]
    const classificationList = [
        {label: "Extempt", value: "Extempt" },
        {label:"Non-Extempt", value: "Non-Extempt"},
        {label: "Full Time", value: "Full Time"},
        {label: "Part-Time", value: "Part-Time"}
    ]
    const booleanList = [
        {label: "Yes", value: "Yes"},
        {label: "No", value: "No"}
    ]

    
    const validateConsensus = (_consensus: Consensus) =>{
        const valid = (_consensus.personalNumber !== undefined && _consensus.personalNumber >0) &&
                        _consensus.firstName.length >0 &&
                        _consensus.lastName.length > 0 &&
                        _consensus.gender.length > 0 &&
                        _consensus.ethnicity.length > 0 &&
                        _consensus.jobTitle.length > 0 && 
                        _consensus.classification.length > 0 &&
                        _consensus.department.length > 0 &&
                        _consensus.supervisor.length > 0 &&
                        _consensus.directReports.length > 0 &&
                        _consensus.offerLetter.length > 0 &&
                        _consensus.positionDescription.length > 0 &&
                        _consensus.policySigned.length > 0 &&
                        _consensus.handbooksRecieved.length > 0 &&
                        _consensus.performanceIssues.length > 0 &&
                        amountIsValid(_consensus.baseSalary) &&
                        amountIsValid(_consensus.annualBonus) &&
                        amountIsValid(_consensus.totalCompensation) &&
                        amountIsValid(_consensus.totalCost);
        if(valid){
            if(_consensus.classification === 'Non-Extempt') {
                if(_consensus.hourlyRate && _consensus.regularHours && _consensus.otHours){
                    return (_consensus.hourlyRate > 0 && _consensus.regularHours > 0 && _consensus.otHours > 0);
                }
                else{
                    return false;
                }
            }
        }
        return valid;
    }

    const isValid = validateConsensus(_consensus);

    const handleChange = (name: string, value: string|boolean) =>{
        setConsensus({..._consensus, [name]: value});
    }

    const handleCancel = () =>{
        if(consensus.id){
            toggleView();
        }
        else{
            history.goBack();
        }
        
    }

    const handleSubmit = () =>{
        setIsLoading(true);
        if(!isValid){
            setShowError(true);
            setIsLoading(false);
        }
        else{
            _consensus.directReports = reports;
            const update = _consensus.id? true: false;
            const request = _consensus.id ? ConsensusService.updateConsensus(_consensus) :
                                            ConsensusService.addConsensus(_consensus)
            request.then(()=>{
                new Promise((resolve) =>{
                    if(update){
                        notificationDispatch({
                            type: NotificationActionType.OPEN,
                            payload: {
                                text: `Employee updated Successfully!`,
                                status: 'info',
                                autoClose: true,
                            },
                        });
                    }
                    else{
                        notificationDispatch({
                            type: NotificationActionType.OPEN,
                            payload: {
                                text: `Employee added Successfully!`,
                                status: 'info',
                                autoClose: true,
                            },
                        });
                    }
                    setIsLoading(false);
                    resolve('OK');
                }).then(() => {
                    setIsLoading(false);
                    history.goBack();
                });
            }).catch((error)=>{
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text:
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString(),
                        status: 'error',
                        autoClose: true,
                    },
                });
                setIsLoading(false);
            })
        }
       
    }
    const addDirectReports = () =>{
        if(newReport.length>0){
            reports.push(newReport);
            setNewReport('');
        }
    }

    const removeDirectReports = (indexToRemove: any) =>{
        setReports([...reports.filter((_, index) => index !== indexToRemove)]);

     };
    
    return(
        <div className = "consensus-form paper">
            <Typography variant = "subheading" weight = {500} color = "secondary" textAlign = "center">
                <span className="page-title">ADD NEW EMPLOYEE</span>
            </Typography>
            <br/><br/>
            <Typography variant ="caption" weight={500} textAlign="right">
                 <span className="color-error">*</span> indicates required
            </Typography>
            
            <div className="form-grid-third">
                <div>
                    <Typography variant = "body">
                        Personal Number  <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value = {_consensus.personalNumber}
                        type = "number"
                        name = "personalNumber" 
                        error = {showError && (_consensus.personalNumber === 0 || _consensus.personalNumber===undefined)}
                        errorText = "Invalid Personal Number"
                    />
                </div>
                <div>
                    <Typography variant = "body">
                        First Name  <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange = {handleChange}
                        value = {_consensus.firstName}
                        name = "firstName"
                        error = {showError && _consensus.firstName.length ===0}
                        errorText = "required"
                    />
                </div>
                <div>
                    <Typography variant = "body">
                        Last Name  <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange = {handleChange}
                        value = {_consensus.lastName}
                        name = "lastName"
                        error = {showError && _consensus.lastName.length ===0}
                        errorText = "required"
                    />
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Date of Birth <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_consensus.dob.toString()}
                        type="date"
                        name="dob"
                        error={showError && !endDateIsValid(_consensus.dob, _consensus.doe)}
                        errorText="Please enter valid date"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Date of Employment <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_consensus.doe.toString()}
                        type="date"
                        name="doe"
                        error={showError && !endDateIsValid(_consensus.dob, _consensus.doe)}
                        errorText="Please enter valid date"
                    />
                </div>
                <div>
                    <Typography variant = "body">
                        Gender <span className="color-error">*</span>
                    </Typography>
                    <InputSelect
                        onChange={handleChange}
                        value={_consensus.gender}
                        name = "gender"
                        options = {genderList}
                        error = {showError && _consensus.gender.length ===0}
                        errorText = "required"
                        placeholder= "Gender"
                   /> 
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                <Typography variant="body">
                    Ethnicity <span className="color-error">*</span>
                </Typography>
                <InputSelect
                        onChange={handleChange}
                        value={_consensus.ethnicity}
                        name = "ethnicity"
                        options = {ethnicityList}
                        error = {showError && _consensus.ethnicity.length ===0}
                        errorText = "required"
                        placeholder= "Ethnicity"                    
                />
                </div>
                <div>
                    <Typography variant="body">
                        Job Title <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value = {_consensus.jobTitle}
                        name = "jobTitle"
                        error = {showError && _consensus.jobTitle.length===0}
                        errorText= "required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Classification <span className="color-error">*</span>
                    </Typography>         
                    <InputSelect
                        onChange={handleChange}   
                        value = {_consensus.classification}
                        name = "classification"
                        options = {classificationList}
                        error = {showError && _consensus.classification.length ===0}
                        errorText = "required"  
                        placeholder="Classsification" 
                    />     
                </div>
            </div>
            <div className = "form-grid-third">
                <div>
                <Typography variant="body">
                    Department <span className="color-error">*</span>
                </Typography>   
                <InputField
                        onChange={handleChange}
                        value = {_consensus.department}
                        name = "department"
                        error = {showError && _consensus.department.length===0}
                        errorText= "required"
                />
                </div>
                <div>
                <Typography variant="body">
                    State <span className="color-error">*</span>
                </Typography>
                <InputSelect
                    onChange={handleChange}
                    value = {_consensus.state}
                    name = "state"
                    options={states}
                    placeholder = "State"
                    error = {showError && _consensus.state.length ===0}
                    errorText = "required"
                 />   
                </div>
                <div>
                <Typography variant="body">
                    Supervisor <span className="color-error">*</span>
                </Typography>   
                <InputField
                        onChange={handleChange}
                        value = {_consensus.supervisor}
                        name = "supervisor"
                        error = {showError && _consensus.supervisor.length===0}
                        errorText= "required"
                />
                </div>
            </div>
            <div className="form-grid-half">
                 <div>
                    <Typography variant="body">
                        Direct Reports <span className="color-error">*</span>
                    </Typography> 
                    <div className = "direct-report">
                        <div className="tags-input">
                            <ul id="tags">
				                {reports.map((tag, index) => (
					                <li key={index} className="tag">
						                <span className='tag-title'>{tag}</span>
						                    <span className='tag-close-icon'
                                            onClick={()=>removeDirectReports(index)}
						                    >
							                    x
						                    </span>
					                </li>
				                ))}
			                </ul>
                            <input  
                                className="input-field"
                                value = {newReport}
                                onChange = {(e) => setNewReport(e.target.value)}                        
                            />
                        </div> 
                        <Button onClick= {addDirectReports}>Add</Button>
                    </div>
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Base Salary <span className="color-error">*</span>
                    </Typography> 
                    <InputField 
                        onChange={handleChange}  
                        type = "number"
                        value={_consensus.baseSalary}
                        name = "baseSalary"
                        error = {showError && !amountIsValid(_consensus.baseSalary)}
                        errorText = "Please enter valid amount."
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Annual Bonus <span className="color-error">*</span>
                    </Typography> 
                    <InputField 
                        onChange={handleChange}  
                        type = "number"
                        value={_consensus.annualBonus}
                        name = "annualBonus"
                        error = {showError && !amountIsValid(_consensus.annualBonus)}
                        errorText = "Please enter valid amount."
                    />
                </div>
            </div>
            {_consensus.classification === "Non-Extempt" &&
            <div className="form-grid-third">
            <div>
            <Typography variant="body">
                Hourly Rate
            </Typography>
            <InputField
                onChange={handleChange} 
                value = {_consensus.hourlyRate}
                name = "hourlyRate"
                disabled = {_consensus.classification !== "Non-Extempt"}
                placeholder = "For Non-Extempt only."
                type = "number"
                error = {showError && ((_consensus.hourlyRate &&_consensus.hourlyRate <= 0) || _consensus.hourlyRate ===undefined)}
                errorText = "Please enter valid amount"
            />
            </div>
            <div>
            <Typography variant="body">
                Regular Hours Worked
            </Typography>
            <InputField
                onChange={handleChange} 
                value = {_consensus.regularHours}
                name = "regularHours"
                disabled = {_consensus.classification !== "Non-Extempt"}
                placeholder = "For Non-Extempt only."
                type = "number"
                error = {showError && ((_consensus.regularHours &&_consensus.regularHours <= 0) || _consensus.regularHours===undefined)}
                errorText = "Please enter valid hours"
            />
            </div>
            <div>
            <Typography variant="body">
                OverTime Hours Worked
            </Typography>
            <InputField
                onChange={handleChange} 
                value = {_consensus.otHours}
                name = "otHours"
                disabled = {_consensus.classification !== "Non-Extempt"}
                placeholder = "For Non-Extempt only."
                type = "number"         
                error = {showError && ((_consensus.otHours && _consensus.otHours <=0) || _consensus.otHours === undefined)}
                errorText = "Please enter valid hours"
            />
            </div>
        </div> }            
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Total Compensation <span className="color-error">*</span>
                    </Typography> 
                    <InputField 
                        onChange={handleChange}  
                        type = "number"
                        value={_consensus.totalCompensation}
                        name = "totalCompensation"
                        error = {showError && !amountIsValid(_consensus.totalCompensation)}
                        errorText = "Please enter valid amount."
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Total Cost <span className="color-error">*</span>
                    </Typography> 
                    <InputField 
                        onChange={handleChange}  
                        type = "number"
                        value={_consensus.totalCost }
                        name = "totalCost"
                        error = {showError && !amountIsValid(_consensus.totalCost)}
                        errorText = "Please enter valid amount."
                    />
                </div>
                <div>
                <Typography variant="body">
                    Offer Letter <span className="color-error">*</span>
                </Typography>
                <InputSelect
                    onChange={handleChange}
                    value = {_consensus.offerLetter}
                    name = "offerLetter"
                    options={booleanList}
                    placeholder = "Yes/No"
                    error = {showError && _consensus.offerLetter.length===0}
                    errorText = "required"
                />
                </div> 
            </div>
            <div className="form-grid-third">
                <div>
                <Typography variant="body">
                    Position Description <span className="color-error">*</span>
                </Typography>
                <InputSelect
                    onChange={handleChange}
                    value = {_consensus.positionDescription}
                    name = "positionDescription"
                    options={booleanList}
                    placeholder = "Yes/No"
                    error = {showError && _consensus.positionDescription.length===0}
                    errorText = "required"
                />
                </div>    
                <div>
                <Typography variant="body">
                    Policy Signed <span className="color-error">*</span>
                </Typography>
                <InputSelect
                    onChange={handleChange}
                    value = {_consensus.policySigned}
                    name = "policySigned"
                    options={booleanList}
                    placeholder = "Yes/No"
                    error = {showError && _consensus.policySigned.length===0}
                    errorText = "required"
                />
                </div>  
                <div>
                <Typography variant="body">
                    Handbooks Received <span className="color-error">*</span>
                </Typography>
                <InputSelect
                    onChange={handleChange}
                    value = {_consensus.handbooksRecieved}
                    name = "handbooksRecieved"
                    options={booleanList}
                    placeholder = "Yes/No"
                    error = {showError && _consensus.handbooksRecieved.length===0}
                    errorText = "required"
                />
                </div> 
            </div>
            <div className = "form-grid-half">
                <div>
                <Typography variant="body">
                    Perfromance Issues <span className="color-error">*</span>
                </Typography>
                <TextArea
                    onChange={handleChange}
                    value = {_consensus.performanceIssues}
                    name = "performanceIssues"
                    error = {showError && _consensus.performanceIssues.length === 0}
                    errorText = "required"
                />
                </div>
                <div>
                <Typography variant="body">
                    Notes 
                </Typography>
                <TextArea
                    onChange={handleChange}
                    value = {_consensus.notes}
                    name = "notes"
                />
                </div>
            </div>
            <div className=" action-container">
            <Button color="primary" onClick={handleCancel}>
                Cancel
            </Button>
            <Button color="primary" onClick={handleSubmit} loading = {isLoading} className = "submit">
                Submit
            </Button>
        </div>
        </div>
    )
}

export default ConsensusForm;