import { GridUser, SystemUser } from '../models/User';
import instance from './instance';

function getUsers(showDisabled?: boolean): Promise<GridUser[]> {
    const request = instance.get(!showDisabled ? 'auth/users' : 'auth/users?showDisabled=true');
    return request.then((response) => {
        return response.data;
    });
}

function getUserById(id: string): Promise<SystemUser> {
    return instance.get(`profile/${id}`).then((response) => {
        return response.data;
    });
}

function getProfile(): Promise<SystemUser> {
    return instance.get('profile/detail').then((response) => {
        return response.data;
    });
}

function deleteUser(id: string): Promise<boolean> {
    return instance.delete(`auth/${id}`).then((response) => {
        return response.data;
    });
}

function enableUser(id: string): Promise<boolean> {
    return instance.put(`auth/enable?Id=${id}`).then((response) => {
        return response.data;
    });
}
function disableUser(id: string): Promise<boolean> {
    return instance.put(`auth/disable?Id=${id}`).then((response) => {
        return response.data;
    });
}

function updateUser(user: SystemUser): Promise<SystemUser> {
    return instance.put(`profile`, user).then((response) => {
        return response.data;
    });
}

export { getUsers, updateUser, deleteUser, enableUser, disableUser, getUserById, getProfile };
