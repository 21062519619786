import React, { useEffect } from 'react';
import UserIcon from '../../../assets/icons/user.svg';
import { urlIsValid } from '../../../utils';
import './avatar.scss';

type Props = {
    source: string;
    name: string;
    size: 'small' | 'medium' | 'large';
};

const Avatar: React.FC<Props> = ({ source, name, size }) => {
    const [imageSrc, setImageSrc] = React.useState<string|null>(null);
    useEffect(() => {
        if(source && urlIsValid(source)) {            
            setImageSrc(source);
        } else {
            setImageSrc(UserIcon)
        }
    }, [source]);

    const handleError = () => {
        setImageSrc(UserIcon);
    };
    return <>{imageSrc && <img src={imageSrc} onError={handleError} alt={name} className={`avatar avatar-${size}`} />}</>;
};

export default Avatar;
