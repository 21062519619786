export default interface User {
    id: string;
    name: string; 
    title: string;    
    emailAddress: string;
    isExisting: boolean;
}

export interface LoginUser extends User {   
    accessToken: string;
    role: UserRole;
    hasTakenTour: boolean;
}

export interface SystemUser extends User {   
    firstName: string;
    lastName: string;
    industry: string;
    lastLoginDate: string;
    dateCreated: string;
    dateUpdated: string;
}

export interface GridUser extends User {     
    isDisabled: boolean;
}

export interface RegisterReq {
    firstName: string;
    lastName: string;
    title: string;
    industry: string;
    isExisting: boolean;
    emailAddress: string;
    password: string;
    confirmPassword: string;
}

export enum UserRole {
    User = "User",
    Admin = "Admin", 
    Moderator = "Moderator",
    Visitor = "Visitor",   
}
