import React from 'react';
import IconButton from '../../components/Core/IconButton';
import Content, { ContentStatus } from '../../models/Content';
import { AuthContext } from '../Authentication/state';
import './article.scss';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { useHistory } from 'react-router-dom';

type Props = {
    content: Content;
};

const DefaultListItemTemplate: React.FC<Props> = ({ content }) => {
    const isEdit = React.useContext(AuthContext).state.isEditorView;
    const history = useHistory();

    const viewDetail = () => {
        history.push((history.location.pathname + '/' + content.id) as string);
    };
    return (
        <div className={`article-item ${ContentStatus[content.status]}`}>
            {isEdit && <IconButton Icon={EditIcon} onClick={() => viewDetail()} />}
            <p className="content-title">{content.title}</p>
            <div dangerouslySetInnerHTML={{ __html: content.data }}></div>
        </div>
    );
};

export default DefaultListItemTemplate;
