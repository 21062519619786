import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ContentService } from '../../api';
import Avatar from '../../components/Core/Avatar';
import Button from '../../components/Core/Button';
import Typography from '../../components/Core/Typography';
import EditablePage from '../../components/EditablePage';
import Content, { ContentConfig, ContentStatus, ContentType } from '../../models/Content';
import { formatDate } from '../../utils';
import ContentManager from '../ContentManager';
import BlogCategory from './BlogCategory';
import BlogListTemplate from './BlogListTemplate';
import './blogs.scss';

const newBlog: Content = {
    type: ContentType.Blog,
    status: ContentStatus.Draft,
    title: '',
    summary: '',
    data: '',
    isFeatured: false,
};
const blogConfig: ContentConfig = {
    canFeature: true,
    hasAuthor: true,
    hasOrder: true,
    hasCategory: true,
    hasSummary: true,
    hasFeaturedImage: true
};

const BlogSingleTemplate: React.FC = () => {
    const [content, setContent] = React.useState<Content | null>(null);
    const [related, setRelated] = React.useState<Content[]>([]);

    const { id } = useParams<{ id: string }>();

    React.useEffect(() => {
        id !== 'add-new'
            ? ContentService.getContent(id).then((response) => {
                  setContent(response);
              })
            : setContent(newBlog);
    }, [id]);
    React.useEffect(() => {
        if (content) {
            ContentService.getRelatedTrending(content.type, content.category).then((response) => {
                setRelated(response.filter((el) => el.id !== id));
            });
        }
    }, [content]);

    return (
        <EditablePage>
            <article>
                {content && (
                    <ContentManager content={content} config={blogConfig}>
                        <div className="blog-container">
                            <section>
                                <div className="article-container">
                                    <BlogCategory category={content.category as string} />
                                    <br />
                                    <Typography variant="heading" weight={600}>
                                        {content.title}
                                    </Typography>
                                    <div className="featured-image">
                                        <img
                                            src={content.featuredImage as string}
                                            alt="featured image"
                                        />
                                    </div>
                                    <Typography variant="caption" textAlign="right">
                                        <strong>Date Published: </strong>
                                        {formatDate(content.createdAt as string)}
                                    </Typography>
                                    <br />
                                    <div dangerouslySetInnerHTML={{ __html: content.data }}></div>
                                </div>
                            </section>
                            <div className="related-article">
                                <div className="paper author">
                                    <Typography variant="subtitle" color="secondary" textAlign="center" weight={800}>
                                        AUTHOR
                                    </Typography>
                                    <Avatar
                                        size="large"
                                        source={content.author?.imageUrl as string}
                                        name={content.author?.name as string}
                                    />
                                    <Typography variant="subtitle" color="secondary" textAlign="center">
                                        {content.author?.name as string}
                                    </Typography>
                                    <Typography variant="body" textAlign="center">
                                        {content.author?.title}
                                    </Typography>
                                    {content.author?.professionalSummary && (
                                        <Typography variant="caption" textAlign="center">
                                            {content.author?.professionalSummary}
                                        </Typography>
                                    )}
                                </div>
                                {related.map((el, index) => (
                                    <BlogListTemplate content={{ ...el, isFeatured: false }} key={index} />
                                ))}
                                <div className="paper">
                                    <Typography variant="title" color="secondary" textAlign="center">
                                        TAKE A SURVEY
                                    </Typography>
                                    <Typography variant="body" textAlign="center">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua.
                                    </Typography>
                                    <Link to={`/surveys/add-new`}>
                                        <Button color="primary" className="d-block mx-auto">
                                            Evaluate
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </ContentManager>
                )}
            </article>
        </EditablePage>
    );
};

export default BlogSingleTemplate;
