import React from "react";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../api";
import Button from "../Core/Button";
import Modal, { ModalActionBar } from "../Core/Modal";
import { NotificationActionType, NotificationContext } from "../Core/Notification/state";
import Typography from "../Core/Typography";

const TestIntro: React.FC = () =>{
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const history = useHistory();
    const closeModal = () =>{
        history.push('/surveys');
    }

    const retakeTour = () =>{
        AuthService.completeTour()
        .then(()=>{
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: `Tour started Successfully.`,
                    status: 'info',
                    autoClose: true,
                },
            });
        }).finally(()=>{
            history.push('/surveys');
            window.location.reload();
        })
        .catch((error)=> {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text:
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString(),
                    status: 'error',
                    autoClose: true,
                },
            });
        }
        );
    }

    return (
        <>            
        <Modal isOpen={true} close={closeModal} title="Retake Tour" size="medium">
        <Typography variant="body">
            Do you want to retake the tour?
        </Typography>
        <ModalActionBar>
            <Button onClick={closeModal} color="error">
                No
            </Button>
            <Button onClick={retakeTour} color="secondary">
                Yes
            </Button>
        </ModalActionBar>
    </Modal></>
    );
}

export default TestIntro