import React from 'react';
import './landing.scss';
import Login from './Login';
import HiresImage from '../../assets/images/hires_logo.png';
import Typography from '../../components/Core/Typography';
import Button from '../../components/Core/Button';
import { Link } from 'react-router-dom';

const LandingPage: React.FC = () => {
    return (
        <section>
            <div className="intro-content">
                <div className="logo-container">
                    <img src={HiresImage} alt="logo" />
                    <div>
                        <h1>CORSIGHT</h1>
                        <h4>Lorem ipsum dolor sit amet</h4>
                    </div>
                </div>
                <Typography variant="subheading" color="white" weight={300}>
                        Lorem ipsum dolor sit amet. Et nostrum provident et voluptatem corrupti qui provident molestiae
                        At velit omnis sit voluptas iure.
                </Typography>
                <Link to="/register">
                    <Button>GET STARTED</Button>
                </Link>
            </div>
            <div className="login-card">
                <Login />
            </div>
        </section>
    );
};

export default LandingPage;
