import React from 'react';
import Button from '../../components/Core/Button';
import InputSelect from '../../components/Core/InputSelect';
import Typography from '../../components/Core/Typography';
import Content, { ContentConfig, ContentType, getStatusOptions } from '../../models/Content';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import OrderSelector from './OrderSelector';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';

type Props = {
    handleChange: (name: string, value: string | boolean | number | null) => void;
    handleSubmit: () => void;
    handleDelete?: () => void;
    content: Content;
    loading: boolean;
    config: ContentConfig;
    isSuccess: boolean;
};

const ContentActions: React.FC<Props> = ({
    handleChange,
    handleDelete,
    content,
    handleSubmit,
    loading,
    config,
    isSuccess,
}) => {
    const featuredOptions = [
        { label: 'Featured', value: 'true' },
        { label: 'Non-Featured', value: 'false' },
    ];

    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const handleOpenDeleteAlert = () => {
        if (handleDelete) {
            alertDialogDispatch({
                type: AlertDialogActionType.OPEN,
                payload: {
                    title: `Delete ${ContentType[content.type]}`,
                    description: `Are you sure you want to delete ${content.title}?`,
                    handleConfirm: () => handleDelete(),
                },
            });
        }
    };

    const handleFeaturedChange = (name: string, value: string) => {
        handleChange(name, value === 'true' ? true : false);
    };
    const handleStatusChange = (name: string, value: string) => {
        handleChange(name, parseInt(value));
    };
    const handleOrderChange =(name: string, value: string | null) => {
        handleChange(name, value? parseInt(value): null);
    }

    const contentStatuses = getStatusOptions();

    return (
        <div className="content-actions">
            <Typography variant="body" color="primary">
                Status
            </Typography>
            <InputSelect
                options={contentStatuses}
                onChange={handleStatusChange}
                name="status"
                value={content.status.toString()}
                placeholder="select Status"
            />
            {config.canFeature && (
                <>
                    <Typography variant="body" color="primary">
                        Featured
                    </Typography>
                    <InputSelect
                        options={featuredOptions}
                        onChange={handleFeaturedChange}
                        name="isFeatured"
                        value={content.isFeatured?.toString()}
                        placeholder=""
                    />
                </>
            )}
            {config.hasOrder && <OrderSelector handleChange={handleOrderChange} order={content.order}/>}
            {content.createdAt && (
                <Typography variant="caption">
                    Created: {new Date(content.createdAt).toLocaleString('en-US')}
                </Typography>
            )}
            {content.updatedAt && (
                <Typography variant="caption">
                    Updated: {new Date(content.updatedAt).toLocaleString('en-US')}
                </Typography>
            )}
            <br />
            {content.id && (
                <Button onClick={handleOpenDeleteAlert} color="error" disabled={loading}>
                    Delete
                </Button>
            )}
            <div className="action">
                <Button onClick={handleSubmit} color="primary" loading={loading}>
                    Save
                </Button>
            </div>
            {isSuccess && (
                <div className="content-loading">
                    <CheckIcon />
                </div>
            )}
        </div>
    );
};

export default ContentActions;
