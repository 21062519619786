import React from 'react';
import { DataLoadingContext } from '../../components/Core/DataLoading';
import {
    Grid,
    GridColumn,
    GridSortChangeEvent,
    GridCellProps,
    GridPageChangeEvent,
    GridFilterChangeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { CompositeFilterDescriptor, orderBy, SortDescriptor, filterBy } from '@progress/kendo-data-query';
import { UserService } from '../../api';
import UserActions from './UserActions';
import Checkbox from '../../components/Core/Checkbox';
import './users.scss';
import { GridUser } from '../../models/User';
import { useHistory } from 'react-router-dom';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import Button from '../../components/Core/Button';

interface PageState {
    skip: number;
    take: number;
}

const initialDataState: PageState = { skip: 0, take: 8 };

const Users: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [users, setUsers] = React.useState<GridUser[]>([]);
    const [filteredData, setFilteredData] = React.useState<GridUser[]>([]);
    const [sort, setSort] = React.useState<SortDescriptor[]>([]);
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();
    const [showDisabled, setShowDisabled] = React.useState(false);
    const history = useHistory();

    const _export = React.useRef<ExcelExport | null>(null);
    const exportExport = () => {
      if (_export.current !== null) {
    
        _export.current.save(users);
      }
    };

    React.useEffect(() => {
        getData();
    }, [showDisabled]);

    const getData = () => {
        toggleLoading(true);
        UserService.getUsers(showDisabled)
            .then((response) => {
                setUsers(response);
            })
            .finally(() => toggleLoading(false));
    };

    React.useEffect(() => {
        if (filter) {
            setFilteredData(filterBy(users, filter));
        } else {
            setFilteredData(users);
        }
    }, [users]);

    const IsExistingCell = (props: GridCellProps) => {
        return <td>{props.dataItem.isExisting ? "Yes": "No"}</td>;
    };

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    const handleFilter = (event: GridFilterChangeEvent) => {
        setFilteredData(filterBy(users, event.filter));
        setFilter(event.filter);
    };

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <UserActions user={props.dataItem as GridUser} fetchData={getData} />
            </td>
        );
    };

    return (
        <div className="users">
            <div className="users-list">
            <ExcelExport ref={_export} fileName = "UsersExport.xlsx">
                <Grid
                    style={{ height: '100%' }}
                    data={orderBy(filteredData.slice(page.skip, page.take + page.skip), sort)}
                    sort={sort}
                    sortable={true}
                    onSortChange={(e: GridSortChangeEvent) => {
                        setSort(e.sort);
                    }}
                    skip={page.skip}
                    take={page.take}
                    total={filteredData.length}
                    pageable={true}
                    onPageChange={pageChange}
                    filterable={true}
                    filter={filter}
                    onFilterChange={handleFilter}
                    // eslint-disable-next-line react/prop-types
                    onRowClick={(props) => history.push(`/users/${props.dataItem.id}`)}
                >
                    <GridToolbar>
                    <div style={{marginBottom: '-16px'}}>
                        <Checkbox
                            checked={showDisabled}
                            onChange={() => setShowDisabled(!showDisabled)}
                            label="Show disabled"
                        />
                        </div>
                        <Button onClick = {exportExport}>Export</Button>
                    </GridToolbar>
                    <GridColumn field="name" title="Name" />
                    <GridColumn field="title" title="Title" />
                    <GridColumn field="isExisting" title="Is Client" cell={IsExistingCell} filter="boolean"/>
                    <GridColumn cell={GridActions} filterable={false} />
                </Grid>
                </ExcelExport>
            </div>
        </div>
    );
};

export default Users;
